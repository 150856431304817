import { useState } from "react";
import EditBar from "./editBar";
import { formatTimestamp } from "../../../helpers/dates-functions";
import { Form, Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { directus_updateNode } from "../../../services/directus/helpers";
import { atom_userInfo } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
const ModalVisibility = ({ editOnly, node, updateNode }) => {
  const [editMode, setEditMode] = useState(editOnly);
  const [editedNode, setEditedNode] = useState(node);
  const [userDidEdit, setUserDidEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasEndDate, setHasEndDate] = useState(node.visible_end);
  const globalUserInfo = useRecoilValue(atom_userInfo);

  const onChangeFormElement = (e, { name, value, checked, type }) => {
    switch (type) {
      case "checkbox":
        setEditedNode({ ...editedNode, [name]: checked });
        break;
      default:
        // datepicker
        if (name === "visible_start") {
          setEditedNode({
            ...editedNode,
            [name]: value,
            always_visible: false,
          });
        }
        if (name === "visible_end") {
          setEditedNode({
            ...editedNode,
            [name]: value,
            always_visible: false,
          });
          setHasEndDate(true);
        }
        setUserDidEdit(true);
    }
    setUserDidEdit(true);
    if (editOnly) {
      // creation screen
      updateNode(editedNode);
    }
  };
  const saveChanges = async () => {
    const updateObject = (({ visible_end, visible_start, always_visible }) => ({
      visible_end,
      visible_start,
      always_visible,
    }))(editedNode);

    const updateResponse = await directus_updateNode(node.id, updateObject);
    if (updateResponse) {
      setEditMode(false);
      updateNode(updateObject);
      setUserDidEdit(false);
    } else {
      setErrorMessage("Er ging iets mis...");
    }
  };
  const renderContent = () => {
    if (editMode) {
      const startDate = editedNode.visible_start
        ? new Date(parseInt(editedNode.visible_start))
        : null;

      const endDate = editedNode.visible_start
        ? new Date(
            parseInt(
              editedNode.visible_end > editedNode.visible_start
                ? editedNode.visible_end
                : editedNode.visible_start
            )
          )
        : null;
      return (
        <div className="visibilityEdit columns">
          <div>
            <Form>
              <Form.Checkbox
                toggle
                name="always_visible"
                label={"Wordt altijd weergegeven."}
                checked={editedNode.always_visible}
                onChange={onChangeFormElement}
              />
              {!editedNode.always_visible ? (
                <Form.Checkbox
                  toggle
                  // name="always_visible"
                  label={"Heeft een einddatum."}
                  checked={hasEndDate}
                  onChange={() => {
                    if (hasEndDate) {
                      setUserDidEdit(true);
                      setEditedNode({ ...editedNode, visible_end: null });
                    }
                    setHasEndDate(!hasEndDate);
                  }}
                />
              ) : null}
            </Form>
          </div>
          <div className="datepickers">
            <div
              className={`datepickerContainer ${
                editedNode.always_visible ? "disabled" : null
              }`}
            >
              <DatePicker
                // showMonthYearPicker
                // showFullMonthYearPicker
                // showFourColumnMonthYearPicker

                // selectsStart
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => {
                  onChangeFormElement(
                    {},
                    {
                      name: "visible_start",
                      value: Date.parse(date),
                    }
                  );
                }}
                inline
              />
            </div>
            <div
              className={`datepickerContainer ${
                editedNode.always_visible || !hasEndDate ? "disabled" : null
              }`}
            >
              <DatePicker
                // showMonthYearPicker
                // showFullMonthYearPicker
                // showFourColumnMonthYearPicker

                // selectsEnd
                minDate={startDate}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => {
                  onChangeFormElement(
                    {},
                    {
                      name: "visible_end",
                      value: Date.parse(date),
                    }
                  );
                }}
                inline
              />
            </div>
          </div>
        </div>
      );
    }
    if (node.always_visible) {
      return (
        <>
          {" "}
          <Icon name="calendar outline" />
          {"Wordt altijd weergegeven."}
        </>
      );
    }
    return (
      <>
        <strong>
          <Icon name="calendar outline" /> {formatTimestamp(node.visible_start)}{" "}
        </strong>{" "}
        t/m{" "}
        <strong>
          {node.visible_end !== null ? (
            <>
              <Icon name="calendar outline" />{" "}
              {formatTimestamp(node.visible_end)}
            </>
          ) : (
            "..."
          )}
        </strong>
      </>
    );
  };
  if (globalUserInfo === 0 || globalUserInfo.role === "user") {
    return null;
  }

  return (
    <section
      className={`visibility withDivider ${editMode ? "editing" : null}`}
    >
      <h6>Zichtbaarheid</h6>
      {renderContent()}
      {!editOnly ? (
        <EditBar
          editMode={editMode}
          setEditMode={setEditMode}
          saveChanges={saveChanges}
          errorMessage={errorMessage}
          userDidEdit={userDidEdit}
        />
      ) : null}
    </section>
  );
};
export default ModalVisibility;
