//https://github.com/directus/directus/blob/main/docs/reference/sdk-js.md

import directus from "./directus";

export const directus_refreshAuth = async () => {
  try {
    await directus.auth.refresh();
    return true;
  } catch (error) {
    return false;
  }
};

export const directus_loginUser = async (email, password) => {
  try {
    await directus.auth.login({
      email,
      password,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const directus_logoutUser = async () => {
  try {
    return await directus.auth.logout();
  } catch (e) {
    return false;
  }
};

export const directus_getAreas = async () => {
  try {
    let apiResponse = await directus.items("areas").read({
      fields: ["*", "nodes.*", "badges.*"],
    });
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_getRelations = async () => {
  try {
    let apiResponse = await directus
      .items("relations")
      .read({ fields: ["from", "to"], limit: -1 });
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_getThemes = async () => {
  try {
    let apiResponse = await directus.items("themes").read({
      fields: ["*"],
    });
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};

export const directus_createNode = async (node) => {
  try {
    let apiResponse = await directus.items("nodes").create([node]);
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_deleteNode = async (node) => {
  try {
    let apiResponse = await directus.items("nodes").delete([node.id]);
    return true;
  } catch (error) {
    return false;
  }
};

export const directus_getNode = async (nodeID) => {
  try {
    let apiResponse = await directus.items("nodes").read(nodeID, {
      fields: [
        "*",
        "themes.themes_id",
        "logbookitems.*",
        "area.id",
        "area.title",
        "area.description",
        "area.type",
      ],
    });
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};

export const directus_updateNode = async (nodeID, updateObject) => {
  try {
    let apiResponse = await directus
      .items("nodes")
      .update(nodeID, updateObject);
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_updateArea = async (areaID, updateObject) => {
  try {
    let apiResponse = await directus
      .items("areas")
      .update(areaID, updateObject);
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};

export const directus_getNodeRelations = async (nodeID) => {
  try {
    let apiResponse = await directus.items("relations").read({
      filter: {
        _or: [
          {
            from: {
              _eq: nodeID,
            },
          },
          {
            to: {
              _eq: nodeID,
            },
          },
        ],
      },
    });

    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_updateNodeRelations = async (nodeID, relations) => {
  const createObject = relations.map((relationID) => {
    if (nodeID < relationID) {
      return { from: nodeID, to: relationID };
    } else {
      return { from: relationID, to: nodeID };
    }
  });

  try {
    let oldRelationIds = await directus_getNodeRelations(nodeID);
    const deleteIDs = oldRelationIds.map((old) => old.id);

    if (oldRelationIds && oldRelationIds.length) {
      let deleteResponse = await directus.items("relations").delete(deleteIDs);
    }

    let apiResponse = await directus.items("relations").create(createObject);

    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_updateNodeThemes = async (node, themes) => {
  const updateObject = themes.map((themeId) => ({
    themes_id: themeId,
    nodes_id: node.id,
  }));

  try {
    const oldThemeRecords = await directus.items("nodes_themes_2").read({
      filter: {
        nodes_id: {
          _eq: node.id,
        },
      },
    });

    const deleteIDs = oldThemeRecords.data.map((oldTheme) => oldTheme.id);

    if (deleteIDs && deleteIDs.length) {
      let deleteResponse = await directus
        .items("nodes_themes_2")
        .delete(deleteIDs);
    }
    let apiResponse = await directus
      .items("nodes_themes_2")
      .create(updateObject);
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};

export const directus_updateAreaBadges = async (areaID, updateObject) => {
  try {
    const oldBadges = await directus.items("areas_areas_2").read({
      filter: {
        areas_id: {
          _eq: areaID,
        },
      },
    });
    const deleteIDs = oldBadges.data.map((oldBadge) => oldBadge.id);

    if (deleteIDs && deleteIDs.length) {
      let deleteResponse = await directus
        .items("areas_areas_2")
        .delete(deleteIDs);
    }
    let apiResponse = await directus
      .items("areas_areas_2")
      .create(updateObject);
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_getUserInfo = async () => {
  try {
    let user = await directus.users.me.read();
    if (user.data.role === undefined) {
      user.data.role = "user";
    }
    return user.data;
  } catch (error) {
    return false;
  }
};
export const directus_getLogbookItemsForArea = async (id) => {
  try {
    const apiResponse = await directus.items("logbookitems").read({
      filter: {
        area: {
          _eq: id,
        },
      },
    });
    return apiResponse.data;
  } catch (error) {
    return false;
  }
};
export const directus_save_logbookItem = async (logbookItem) => {
  let id = null;
  if (logbookItem.id !== undefined) {
    id = logbookItem.id;
    delete logbookItem.id;
  }

  try {
    if (id === null) {
      const createResponse = await directus
        .items("logbookitems")
        .create(logbookItem);
      return createResponse.data;
    } else {
      const updateResponse = await directus
        .items("logbookitems")
        .update(id, logbookItem);
      return updateResponse.data;
    }
  } catch (error) {
    return false;
  }
};
export const directus_delete_logbookItem = async (id) => {
  try {
    await directus.items("nodes").delete([id]);
    return true;
  } catch (error) {
    return false;
  }
};
