import {
  atom_zoomedExperimenteerGebied,
  atom_zoomedOntwikkeltraject,
  atom_modal_state,
} from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";
const Tag = ({ area, children }) => {
  const setZoomedExperimenteerGebied = useSetRecoilState(
    atom_zoomedExperimenteerGebied
  );
  const setZoomedOntwikkeltraject = useSetRecoilState(
    atom_zoomedOntwikkeltraject
  );
  const setModalState = useSetRecoilState(atom_modal_state);

  return (
    <span
      onClick={() => {
        if (area && area.id) {
          console.log(area.type);
          if (area.type === "gebied") {
            setZoomedExperimenteerGebied(area.id);
            setModalState({ mode: "hidden" });
          }
          if (area.type === "ontwikkeltraject" || area.type === "KKL") {
            setZoomedOntwikkeltraject(area.id);
            setModalState({ mode: "hidden" });
          }
        }
      }}
      className="tag"
    >
      {children}
    </span>
  );
};
export default Tag;
