import { atom } from "recoil";

export const atom_areas = atom({
  key: "atom_areas",
  default: [],
});
export const atom_relations = atom({
  key: "atom_relations",
  default: [],
});

export const atom_themes = atom({
  key: "atom_themes",
  default: [],
});

export const atom_currently_selected_node = atom({
  key: "atom_currently_selected_node",
  default: null,
});

export const atom_currently_selected_ontwikkeltraject = atom({
  key: "atom_currently_selected_ontwikkeltraject",
  default: null,
});

export const atom_modal_state = atom({
  key: "atom_modal_state",
  default: {
    mode: "hidden",
    type: null,
  },
});

export const atom_selected_timeline_date = atom({
  key: "atom_selected_timeline_date",
  default: Date.now(),
});

export const atom_reloadNodesTrigger = atom({
  key: "atom_reloadNodesTrigger",
  default: null,
});

export const atom_showdashboardScreen = atom({
  key: "atom_showLoginScreen",
  default: false,
});

export const atom_userInfo = atom({
  key: "atom_userInfo",
  default: 0,
});

export const atom_zoomedExperimenteerGebied = atom({
  key: "atom_zoomedExperimenteerGebied",
  default: false,
});
export const atom_zoomedOntwikkeltraject = atom({
  key: "atom_zoomedOntwikkeltraject",
  default: false,
});

export const atom_anonymizeNames = atom({
  key: "atom_anonymizeNames",
  default: false,
});
