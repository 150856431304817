export const formatTimestamp = (timestamp) => {
  var date = new Date(parseInt(timestamp));

  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  return dd + "-" + mm + "-" + yyyy;
};

export const logBookFormatTimestamp = (timestamp) => {
  var date = new Date(parseInt(timestamp));

  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!

  var yyyy = date.getFullYear();

  return (
    <>
      <datetime className="daymonth">{`${dd} - ${mm}`} </datetime>
      <datetime className="year">{`${yyyy}`}</datetime>
    </>
  );
};
