import { useSetRecoilState } from "recoil";
import { atom_currently_selected_node } from "../../../recoil/atoms";
import { Form } from "semantic-ui-react";
import icon_experiment from "../../../img/icon_experiment.svg";
import icon_gremium from "../../../img/icon_gremium.svg";
const NonPersonCard = ({
  node,
  editMode = false,
  onChangeFormElement,
  showValidationErrors = false,
  asLink = false,
}) => {
  const setCurrentlySelectedNode = useSetRecoilState(
    atom_currently_selected_node
  );

  return (
    <div
      className="card"
      onClick={() => {
        if (!asLink) {
          return false;
        }
        setCurrentlySelectedNode(node.id);
      }}
    >
      <div className="icon">
        <img
          src={node.type === "experiment" ? icon_experiment : icon_gremium}
          alt=""
        />
      </div>
      <div>
        {editMode ? (
          <>
            <Form>
              <Form.Group>
                {node.type !== "experiment" ? (
                  <Form.Input
                    name="first_name"
                    value={node.first_name}
                    onChange={onChangeFormElement}
                    placeholder="Type (bijv. klankbordgroep)"
                    width={15}
                    error={showValidationErrors && node.first_name === ""}
                  />
                ) : null}

                <Form.Input
                  name="last_name"
                  value={node.last_name}
                  onChange={onChangeFormElement}
                  placeholder="Titel"
                  width={15}
                  error={showValidationErrors && node.last_name === ""}
                />
              </Form.Group>
              {node.type === "experiment" ? (
                <Form.Group>
                  <Form.TextArea
                    name="description"
                    value={node.description}
                    width={15}
                    onChange={onChangeFormElement}
                    placeholder="Korte omschrijving"
                  />
                </Form.Group>
              ) : null}
            </Form>
          </>
        ) : (
          <>
            <h1>
              {node.type !== "experiment" ? node.first_name : ""}{" "}
              {node.last_name}
            </h1>
            <div className="description">{node.description}</div>
          </>
        )}
      </div>
    </div>
  );
};
export default NonPersonCard;
