import { Suspense, useEffect, useState } from "react";
import {
  atom_currently_selected_node,
  atom_currently_selected_ontwikkeltraject,
  atom_reloadNodesTrigger,
  atom_areas,
} from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { directus_getNode } from "../../services/directus/helpers";
import { Loader, Button } from "semantic-ui-react";
import ModalHeader from "./modalComponents/modalHeader";
import ModalRelations from "./modalComponents/modalRelations";
import ModalRelatedThemes from "./modalComponents/modalRelatedThemes";
import ModalVisibility from "./modalComponents/ModalVisibility";
import ModalLogBook from "./modalComponents/modalLogbook";
import ModalDeleteNode from "./modalComponents/modalDeleteNode";

const ViewDetailModal = ({ type }) => {
  const nodeId = useRecoilValue(atom_currently_selected_node);
  const ontwikkeltrajectId = useRecoilValue(
    atom_currently_selected_ontwikkeltraject
  );

  const [node, setNode] = useState({ state: "loading", content: [] });
  const [ontwikkeltraject, setOntwikkeltraject] = useState({
    state: "loading",
    content: [],
  });

  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);
  const areas = useRecoilValue(atom_areas);

  useEffect(() => {
    (async () => {
      if (nodeId !== null && nodeId !== node.content.id) {
        const loadedNode = await directus_getNode(nodeId);
        setNode({ state: "loaded", content: loadedNode });
      }
      if (
        ontwikkeltrajectId !== null &&
        ontwikkeltrajectId !== ontwikkeltraject.content.id
      ) {
        setOntwikkeltraject({
          state: "loaded",
          content: areas.find((a) => a.id === ontwikkeltrajectId),
        });
      }
    })();
  }, [nodeId, ontwikkeltrajectId]);

  const updateNode = (updatedItems) => {
    setNode({ state: "loaded", content: { ...node.content, ...updatedItems } });
    setReloadNodesTrigger(Date.now());
  };

  if (type === "person" || type === "experiment" || type === "gremium") {
    if (node.state === "loading") {
      return <Loader active />;
    }
    return (
      <>
        <ModalHeader node={node.content} updateNode={updateNode} />
        <div className="scrollable">
          <ModalRelatedThemes node={node.content} updateNode={updateNode} />
          {node.content.type !== "experiment" ? (
            <Suspense fallback="loading...">
              <ModalRelations
                nodeId={node.content.id}
                updateNode={updateNode}
              />
            </Suspense>
          ) : null}
          <ModalLogBook node={node.content} />
          <ModalVisibility node={node.content} updateNode={updateNode} />
          <ModalDeleteNode node={node.content} />
        </div>
      </>
    );
  }

  if (type === "gremium") {
    <></>;
  }

  //ontwikkeltraject
  if (ontwikkeltraject.state === "loading") {
    return <Loader active />;
  }

  return (
    <>
      <ModalHeader
        type={type}
        ontwikkeltraject={ontwikkeltraject.content}
        updateNode={() => {}}
      />
      <div className="scrollable">
        <ModalLogBook ontwikkeltraject={ontwikkeltraject.content} />
      </div>
    </>
  );
};
export default ViewDetailModal;
