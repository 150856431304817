export const stripToSaveFromName = (inputString) => {
  return inputString
    .trim()
    .replace(/[\W_]+/g, "")
    .toLowerCase();
};

export const nl2br = (str) => {
  if (str === null) {
    str = "";
  }
  return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
};
