import { selector } from "recoil";
import {
  atom_areas,
  atom_selected_timeline_date,
  atom_currently_selected_node,
  atom_zoomedExperimenteerGebied,
  atom_anonymizeNames,
} from "./atoms";
import { directus_getNode } from "../services/directus/helpers";
import { area } from "d3";
import { m } from "framer-motion";

export const selector_timelineBoundaries = selector({
  key: "selector_timelineBoundaries",
  get: async ({ get }) => {
    const allAreas = get(atom_areas);
    let min = Date.now(),
      max = Date.now();

    for (let i = 0; i < allAreas.length; i++) {
      const area = allAreas[i];
      if (area.nodes) {
        for (let j = 0; j < area.nodes.length; j++) {
          const node = area.nodes[j];
          if (!node.always_visible) {
            const start = parseInt(node.visible_start);
            const end = parseInt(node.visible_end);

            min = start < min ? start : min;
            max = end > max ? end : max;
          }
        }
      }
    }
    return { min: min, max: max };
  },
});
const randomString = (length) => {
  var result = "";
  var characters = "abdefghijklmnoprstuvw";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    if (i === 0) {
      result = result.toUpperCase();
    }
  }
  return result;
};

export const selector_filtered_areas = selector({
  key: "selector_filtered_areas",
  get: async ({ get }) => {
    let allAreas = get(atom_areas);
    const anonymizeNames = get(atom_anonymizeNames);

    const selectedTimelineDate = get(atom_selected_timeline_date);
    return allAreas.map((area) => {
      if (area.nodes === null) {
        return area;
      }
      let filteredNodes = area.nodes.filter((node) => {
        if (node.always_visible) {
          return true;
        }
        if (node.visible_end === null) {
          return node.visible_start <= selectedTimelineDate;
        }
        return (
          node.visible_start <= selectedTimelineDate &&
          node.visible_end >= selectedTimelineDate
        );
      });
      if (anonymizeNames) {
        filteredNodes = filteredNodes.map((node) => {
          if (node.type !== "person") return node;
          switch (node.first_name) {
            case "SKI":
            case "EAI":
            case "EIA":
            case "PAV":
            case "DAD":
            case "PRV":
            case "DI":
              return node;
            default:
          }
          return {
            ...node,
            first_name: randomString(node.first_name.length),
            last_name: randomString(node.last_name.length),
          };
        });
      }
      if (area.badges && area.badges.length > 0) {
        const enrichtedBadges = area.badges.map((badge) => {
          if (parseInt(badge.visible_start) <= selectedTimelineDate) {
            return { ...badge, visible: true };
          }
          return { ...badge, visible: false };
        });
        area = { ...area, badges: enrichtedBadges };
      }

      return { ...area, nodes: filteredNodes };
    });
  },
});

export const selector_all_people_by_type = selector({
  key: "selector_all_people_by_type",
  get: async ({ get }) => {
    let allNodes = [];
    const areas = get(atom_areas);
    for (let i = 0; i < areas.length; i++) {
      let areanodes = [];
      if (areas[i].nodes) {
        areanodes = areas[i].nodes.map((n) => {
          return { ...n, areatype: areas[i].type };
        });
      }

      allNodes = allNodes.concat(areanodes);
    }
    return allNodes.filter((node) => {
      return node !== null;
    });

    return allNodes;
  },
});
