import { useSetRecoilState } from "recoil";
import { atom_currently_selected_ontwikkeltraject } from "../../../recoil/atoms";
import { stripToSaveFromName } from "../../../helpers/string-functions";
const OntwikkeltrajectCard = ({ ontwikkeltraject, asLink = false }) => {
  const setCurrentlySelectedOntwikkeltraject = useSetRecoilState(
    atom_currently_selected_ontwikkeltraject
  );

  return (
    <div
      className="ontwikkeltrajectCard"
      onClick={() => {
        if (!asLink) {
          return false;
        }
        setCurrentlySelectedOntwikkeltraject(ontwikkeltraject.id);
      }}
    >
      <div className={`icon ${stripToSaveFromName(ontwikkeltraject.title)}`}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.314 0L16 4.68597V11.314L11.314 16H4.68597L0 11.314V4.68597L4.68597 0H11.314Z"
            fill="#FE7342"
          />
        </svg>
      </div>
      <div>
        <h1>{ontwikkeltraject.title}</h1>
        <div className="description">{ontwikkeltraject.description}</div>
      </div>
    </div>
  );
};
export default OntwikkeltrajectCard;
