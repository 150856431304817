import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import {
  atom_modal_state,
  atom_currently_selected_node,
} from "../../recoil/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import ViewDetailModal from "./viewDetailModal";
import CreateDetailModal from "./createDetailModal";
import { Icon } from "semantic-ui-react";
import { motion, AnimatePresence } from "framer-motion";
const Modal = (props) => {
  const [modalState, setModalState] = useRecoilState(atom_modal_state);
  const nodeId = useRecoilValue(atom_currently_selected_node);
  const [modals, setModals] = useState([]);
  useEffect(() => {
    setModals([nodeId]);
  }, [nodeId]);
  const hideModal = () => {
    setModalState({ mode: "hidden" });
  };

  let content = null;

  switch (modalState.mode) {
    default:
    case "view":
      content = <ViewDetailModal type={modalState.type} />;
      break;
    case "create":
      content = <CreateDetailModal />;
      break;
  }
  return ReactDOM.createPortal(
    <AnimatePresence>
      {modalState.mode === "hidden" ? null : (
        <motion.div
          key={`modal_dimmer`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          exit={{
            opacity: 0,
            transition: { duration: 0.2 },
          }}
          className="dimmer"
          onClick={() => {
            hideModal();
          }}
        >
          <AnimatePresence exitBeforeEnter>
            {modals.map((modal) => (
              <motion.div
                key={`modal_${modal}`}
                initial={{ opacity: 0, y: 50, scale: 1.1 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  transition: { delay: 0, duration: 0.5 },
                }}
                exit={{
                  opacity: 0,
                  y: -100,
                  scale: 1,
                  transition: { duration: 0.3 },
                }}
                className="modal"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  className="closeButton"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  <Icon circular name="close" />
                </div>
                {content}
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById("modalContainer")
  );
};

export default Modal;
