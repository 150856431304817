import { useEffect, useState } from "react";
import {
  atom_zoomedExperimenteerGebied,
  atom_areas,
  atom_modal_state,
  atom_reloadNodesTrigger,
  atom_currently_selected_ontwikkeltraject,
} from "../../recoil/atoms";
import { selector_filtered_areas } from "../../recoil/selectors";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import arrow_back from "../../img/icon_arrow_back.svg";
import icon_plus from "../../img/icon_plus.svg";
import { Form, Icon, Button } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { formatTimestamp } from "../../helpers/dates-functions";
import { stripToSaveFromName, nl2br } from "../../helpers/string-functions";
import { directus_updateAreaBadges } from "../../services/directus/helpers";
import { AnimatePresence, motion } from "framer-motion";
const RightWindow = ({ globalUserInfo }) => {
  const [selectedAreaId, setSelectedArea] = useRecoilState(
    atom_zoomedExperimenteerGebied
  );

  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);

  const areas = useRecoilValue(selector_filtered_areas);
  const setModalState = useSetRecoilState(atom_modal_state);
  const setCurrentlySelectedOntwikkeltraject = useSetRecoilState(
    atom_currently_selected_ontwikkeltraject
  );

  const [area, setArea] = useState({ title: "" });
  const [showLine, setShowLine] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [stagedBadges, setStagedBadges] = useState([]);
  const [userDidEdit, setUserDidEdit] = useState(false);

  useEffect(() => {
    setArea(areas.find((a) => a.id === selectedAreaId));

    setEditMode(false);
    if (selectedAreaId) {
      setShowLine(true);
      setTimeout(() => {
        setShowLine(false);
      }, 400);
    }
  }, [selectedAreaId, areas]);

  const openBadgeModal = (id) => {
    setCurrentlySelectedOntwikkeltraject(id);
    setModalState({
      mode: "view",
      type: "ontwikkeltraject",
    });
  };
  const openEditMode = () => {
    setStagedBadges(area.badges !== null ? area.badges : []);
    setEditMode(true);
  };
  const saveBadges = async () => {
    const updateObject = stagedBadges.map((badge) => {
      return {
        areas_id: badge.areas_id,
        areas_related_id: badge.areas_related_id,
        visible_start: badge.visible_start,
      };
    });

    const updateResponse = await directus_updateAreaBadges(
      area.id,
      updateObject
    );

    if (updateResponse) {
      setEditMode(false);
      setUserDidEdit(false);
      setReloadNodesTrigger(Date.now());
    } else {
      setErrorMessage("Er ging iets mis...");
    }
  };

  if (!selectedAreaId) {
    return (
      <div className="right">
        <h2>Experimenteergebieden</h2>
        {/* {globalUserInfo !== 0 ? (
          <div
            class="button"
            onClick={() => {
              setModalState({
                mode: "create",
                type: "experiment",
                area: area,
              });
            }}
          >
            <img src={icon_plus} />
            Experimenteergebied toevoegen
          </div>
        ) : null} */}
      </div>
    );
  }
  const renderBadges = () => {
    if (!area) return null;

    if (!editMode) {
      return (
        <div className="badges">
          {area.badges !== null
            ? area.badges.map((b, i) => {
                const badgeArea = areas.find(
                  (a) => a.id === b.areas_related_id
                );
                if (b.visible !== true) {
                  return null;
                }

                return (
                  <motion.div
                    key={`badge-icon-${badgeArea.id}`}
                    className={`badge ${stripToSaveFromName(badgeArea.title)}`}
                    onClick={() => openBadgeModal(badgeArea.id)}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { delay: 0.1 * i + 0.5 },
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.314 0L16 4.68597V11.314L11.314 16H4.68597L0 11.314V4.68597L4.68597 0H11.314Z"
                        fill="#FE7342"
                      />
                    </svg>
                    {badgeArea.title}
                  </motion.div>
                );
              })
            : globalUserInfo !== 0 && globalUserInfo.role !== "user"
            ? "Edit trajecten"
            : null}
          {globalUserInfo !== 0 && globalUserInfo.role !== "user" ? (
            <div
              className="edit"
              onClick={() => {
                openEditMode();
              }}
            >
              <Icon name="edit outline" />
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <>
        <div className="badges edit">
          {areas.map((a) => {
            if (a.type !== "ontwikkeltraject") {
              return null;
            }

            const checked = stagedBadges.some(
              (badge) => badge.areas_related_id === a.id
            );
            let startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            let badgeInfo = {};
            if (checked) {
              badgeInfo = stagedBadges.filter(
                (badge) => badge.areas_related_id === a.id
              );
              badgeInfo = badgeInfo.slice(0);
              badgeInfo = badgeInfo[0];
            }

            return (
              <div>
                <Form.Checkbox
                  label={`${a.title}`}
                  checked={checked}
                  onClick={(e, { checked }) => {
                    if (checked) {
                      setStagedBadges(
                        stagedBadges.concat({
                          areas_id: selectedAreaId,
                          areas_related_id: a.id,
                          visible_start: Date.parse(startDate),
                        })
                      );
                    } else {
                      setStagedBadges(
                        stagedBadges.filter((p) => a.id !== p.areas_related_id)
                      );
                    }

                    setUserDidEdit(true);
                  }}
                />
                {checked ? (
                  <DatePicker
                    customInput={
                      <span>
                        zichtbaar vanaf{" "}
                        {badgeInfo.visible_start === null
                          ? formatTimestamp(startDate)
                          : formatTimestamp(badgeInfo.visible_start)}
                      </span>
                    }
                    onChange={(date) => {
                      setStagedBadges(
                        stagedBadges.map((p) => {
                          if (a.id !== p.areas_related_id) return p;
                          return { ...p, visible_start: Date.parse(date) };
                        })
                      );
                    }}
                  />
                ) : null}
              </div>
            );
          })}
          <div className="editbar">
            <div onClick={() => saveBadges()}>
              <Icon name="checkmark" color={userDidEdit ? "green" : null} />
              Opslaan
            </div>
            <div
              onClick={() => {
                setEditMode(false);
              }}
            >
              <Icon name="cancel" color="red" />
              Annuleren
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={`right ${editMode ? "editmode" : ""}`}>
      {/* <AnimatePresence>
        {showLine ? (
          <motion.div
            key="ui-line-right"
            className="ui-line"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { duration: 0.2 },
            }}
          />
        ) : null}
      </AnimatePresence> */}

      {globalUserInfo !== 0 && globalUserInfo.role !== "user" ? (
        <div className="buttonGroupBottom">
          <div
            className="button"
            onClick={() => {
              setModalState({
                mode: "create",
                type: "person",
                area: area,
              });
            }}
          >
            <img src={icon_plus} />
            Persoon toevoegen
          </div>
          <div
            className="button"
            onClick={() => {
              setModalState({
                mode: "create",
                type: "experiment",
                area: area,
              });
            }}
          >
            <img src={icon_plus} />
            Experiment toevoegen
          </div>
        </div>
      ) : null}
      <h2
        className="active"
        onClick={() => {
          setSelectedArea(false);
        }}
      >
        <img src={arrow_back} className="arrow" />
        Experimenteergebieden
      </h2>
      {area ? (
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
        >
          {area.title}
        </motion.h1>
      ) : null}

      {renderBadges()}
    </div>
  );
};
export default RightWindow;
