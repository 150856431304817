import { Icon } from "semantic-ui-react";
import {
  atom_showdashboardScreen,
  atom_userInfo,
  atom_zoomedExperimenteerGebied,
  atom_zoomedOntwikkeltraject,
} from "../recoil/atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { motion } from "framer-motion";
const Header = () => {
  const setSelectedExperimenteerGebied = useSetRecoilState(
    atom_zoomedExperimenteerGebied
  );
  const setSelectedOntwikkelTraject = useSetRecoilState(
    atom_zoomedOntwikkeltraject
  );

  const resetViews = () => {
    setSelectedOntwikkelTraject(false);
    setSelectedExperimenteerGebied(false);
  };
  return (
    <motion.header
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.25 } }}
    >
      <div className="logo" onClick={() => resetViews()}>
        <img src="/img/logo-rijksoverheid.jpg" />
      </div>
      <h1 onClick={() => resetViews()}>
        Kringlooplandbouw
        <span>Trajectkaart</span>
      </h1>
      <LoginButton />
    </motion.header>
  );
};

export default Header;

const LoginButton = () => {
  const setShowLoginScreen = useSetRecoilState(atom_showdashboardScreen);
  const globalUserInfo = useRecoilValue(atom_userInfo);

  return (
    <div
      className="loginButton"
      onClick={() => {
        setShowLoginScreen(true);
      }}
    >
      <Icon name="user outline" size="large" />
      {globalUserInfo
        ? `${globalUserInfo.first_name ? globalUserInfo.first_name + " " : ""}${
            globalUserInfo.last_name
          }`
        : null}
    </div>
  );
};
