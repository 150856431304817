import { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { atom_currently_selected_node } from "../../../recoil/atoms";
import { Form } from "semantic-ui-react";
import icon_dossierhouder from "../../../img/icon_dossierhouder.svg";
import icon_manager from "../../../img/icon_manager.svg";
import icon_projectleider from "../../../img/icon_projectleider.svg";
import icon_beleidsmedewerker from "../../../img/icon_beleidsmedewerker.svg";
import icon_gebiedsmanager from "../../../img/icon_gebiedsmanager.svg";
import icon_ondernemer from "../../../img/icon_ondernemer.svg";
import icon_communicatiemedewerker from "../../../img/icon_communicatiemedewerker.svg";
import icon_lagereoverheid from "../../../img/icon_lagereoverheid.svg";
import icon_expertonderzoeker from "../../../img/icon_expertonderzoeker.svg";
import icon_emptyrole from "../../../img/icon_emptyrole.svg";

const PersonCard = ({
  person,
  editMode = false,
  onChangeFormElement,
  showValidationErrors = false,
  asLink = false,
}) => {
  const setCurrentlySelectedNode = useSetRecoilState(
    atom_currently_selected_node
  );
  const personRoles = [
    {
      key: "dossierhouder",
      value: "dossierhouder",
      text: "Dossierhouder",
    },
    {
      key: "manager",
      value: "manager",
      text: "Manager",
    },
    {
      key: "projectleider",
      value: "projectleider",
      text: "Projectleider",
    },
    {
      key: "beleidsmedewerker",
      value: "beleidsmedewerker",
      text: "Beleidsmedewerker",
    },
    {
      key: "projectmedewerker",
      value: "projectmedewerker",
      text: "Projectmedewerker",
    },
    {
      key: "medewerker",
      value: "medewerker",
      text: "Medewerker",
    },
    {
      key: "gebiedsmanager",
      value: "gebiedsmanager",
      text: "Gebiedsmanager",
    },
    {
      key: "programmamanager",
      value: "programmamanager",
      text: "Programmamanager",
    },
    {
      key: "ondernemer",
      value: "ondernemer",
      text: "(Agrarisch) ondernemer",
    },
    {
      key: "communicatiemedewerker",
      value: "communicatiemedewerker",
      text: "Communicatiemedewerker",
    },
    {
      key: "lokaleoverheid",
      value: "lokaleoverheid",
      text: "Lokale overheid",
    },
    {
      key: "onderzoeker",
      value: "onderzoeker",
      text: "Onderzoeker",
    },
    {
      key: "expert",
      value: "expert",
      text: "Expert",
    },
  ];

  let icon = null;
  switch (person.role) {
    case "dossierhouder":
      icon = icon_dossierhouder;
      break;
    case "manager":
      icon = icon_manager;
      break;
    case "projectleider":
      icon = icon_projectleider;
      break;
    case "beleidsmedewerker":
    case "projectmedewerker":
    case "medewerker":
      icon = icon_beleidsmedewerker;
      break;
    case "gebiedsmanager":
    case "programmamanager":
      icon = icon_gebiedsmanager;
      break;
    case "ondernemer":
      icon = icon_ondernemer;
      break;
    case "communicatiemedewerker":
      icon = icon_communicatiemedewerker;
      break;
    case "lagereoverheid":
    case "lokaleoverheid":
      icon = icon_lagereoverheid;
      break;
    case "expertonderzoeker":
    case "expert":
    case "onderzoeker":
      icon = icon_expertonderzoeker;
      break;
    default:
      icon = icon_emptyrole;
  }

  let currentRoleObject = personRoles.filter(
    (role) => role.key === person.role
  );
  if (currentRoleObject && undefined != currentRoleObject[0]) {
    currentRoleObject = currentRoleObject[0];
  } else {
    currentRoleObject = { text: "" };
  }

  return (
    <div
      className="personCard"
      onClick={() => {
        if (!asLink) {
          return false;
        }
        setCurrentlySelectedNode(person.id);
      }}
    >
      <div className="icon">
        <img src={icon} alt="" />
      </div>
      <div>
        {editMode ? (
          <>
            <Form>
              <Form.Group>
                <Form.Input
                  name="first_name"
                  value={person.first_name}
                  width={8}
                  onChange={onChangeFormElement}
                  placeholder="voornaam"
                  error={showValidationErrors && person.first_name === ""}
                />
                <Form.Input
                  name="last_name"
                  value={person.last_name}
                  width={8}
                  onChange={onChangeFormElement}
                  placeholder="achternaam"
                  error={showValidationErrors && person.last_name === ""}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: "0.2em" }}>
                <Form.Select
                  name="role"
                  options={personRoles}
                  value={person.role}
                  width={10}
                  onChange={onChangeFormElement}
                  placeholder="functie"
                  error={showValidationErrors && person.role === ""}
                />
                <Form.Checkbox
                  name="is_contactperson"
                  checked={person.is_contactperson}
                  width={6}
                  onChange={onChangeFormElement}
                  label="Primair contactpersoon"
                  style={{ marginTop: "0.5em" }}
                />
              </Form.Group>
            </Form>
          </>
        ) : (
          <>
            <h1>
              {person.first_name} {person.last_name}
            </h1>
            <h2>{currentRoleObject.text}</h2>
          </>
        )}
      </div>
    </div>
  );
};
export default PersonCard;
