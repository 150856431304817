import { stripToSaveFromName } from "../string-functions";
import { linkHorizontal, transition } from "d3";

const symbolPaths = {
  beleidsmedewerker:
    "M9.45265 1.03053L9.00073 0.572519C8.24753 -0.19084 7.04241 -0.19084 6.43985 0.572519C5.68665 1.33588 4.48153 1.33588 3.87897 0.572519C3.12578 -0.19084 1.92066 -0.19084 1.3181 0.572519L0.866179 1.03053C-0.1883 1.79389 -0.1883 2.8626 0.564899 3.62595C1.3181 4.38931 1.3181 5.61069 0.564899 6.37405C-0.1883 7.1374 -0.1883 8.35878 0.564899 8.96947L1.16746 9.42748C1.92066 10.1908 3.12577 10.1908 3.72833 9.42748C4.48153 8.66412 5.68665 8.66412 6.28921 9.42748C7.04241 10.1908 8.24753 10.1908 8.85009 9.42748L9.30201 8.96947C10.0552 8.20611 10.0552 6.98473 9.30201 6.37405C8.54881 5.61069 8.54881 4.38931 9.30201 3.77862C10.2058 2.86259 10.2058 1.79389 9.45265 1.03053Z",
  projectmedewerker:
    "M9.45265 1.03053L9.00073 0.572519C8.24753 -0.19084 7.04241 -0.19084 6.43985 0.572519C5.68665 1.33588 4.48153 1.33588 3.87897 0.572519C3.12578 -0.19084 1.92066 -0.19084 1.3181 0.572519L0.866179 1.03053C-0.1883 1.79389 -0.1883 2.8626 0.564899 3.62595C1.3181 4.38931 1.3181 5.61069 0.564899 6.37405C-0.1883 7.1374 -0.1883 8.35878 0.564899 8.96947L1.16746 9.42748C1.92066 10.1908 3.12577 10.1908 3.72833 9.42748C4.48153 8.66412 5.68665 8.66412 6.28921 9.42748C7.04241 10.1908 8.24753 10.1908 8.85009 9.42748L9.30201 8.96947C10.0552 8.20611 10.0552 6.98473 9.30201 6.37405C8.54881 5.61069 8.54881 4.38931 9.30201 3.77862C10.2058 2.86259 10.2058 1.79389 9.45265 1.03053Z",
  medewerker:
    "M9.45265 1.03053L9.00073 0.572519C8.24753 -0.19084 7.04241 -0.19084 6.43985 0.572519C5.68665 1.33588 4.48153 1.33588 3.87897 0.572519C3.12578 -0.19084 1.92066 -0.19084 1.3181 0.572519L0.866179 1.03053C-0.1883 1.79389 -0.1883 2.8626 0.564899 3.62595C1.3181 4.38931 1.3181 5.61069 0.564899 6.37405C-0.1883 7.1374 -0.1883 8.35878 0.564899 8.96947L1.16746 9.42748C1.92066 10.1908 3.12577 10.1908 3.72833 9.42748C4.48153 8.66412 5.68665 8.66412 6.28921 9.42748C7.04241 10.1908 8.24753 10.1908 8.85009 9.42748L9.30201 8.96947C10.0552 8.20611 10.0552 6.98473 9.30201 6.37405C8.54881 5.61069 8.54881 4.38931 9.30201 3.77862C10.2058 2.86259 10.2058 1.79389 9.45265 1.03053Z",
  communicatiemedewerker:
    "M4.33333 9.74861L0.333333 5.65192C-0.111111 5.19673 -0.111111 4.58981 0.333333 4.28635L4.33333 0.341391C4.77778 -0.113797 5.37037 -0.113797 5.66667 0.341391L9.66667 4.43808C10.1111 4.89327 10.1111 5.50018 9.66667 5.80364L5.66667 9.90033C5.37037 10.0521 4.77778 10.0521 4.33333 9.74861Z",
  dossierhouder:
    "M3.6829 0.33341L0.659258 2.6721C0.0545284 3.13984 -0.0966539 4.07532 0.0545284 4.69897L1.26399 8.7527C1.56635 9.53226 2.17108 10 3.07818 10H6.85773C7.61365 10 8.36956 9.53226 8.67192 8.7527L9.88138 4.85488C10.1837 4.07532 9.88138 3.29575 9.27665 2.82801L6.25301 0.489323C5.34591 -0.134328 4.43882 -0.134328 3.6829 0.33341Z",
  expertonderzoeker:
    "M3.93964 0.687023C4.39408 -0.229008 5.7574 -0.229008 6.21184 0.687023L8.02959 4.19847L9.84734 7.70992C10.3018 8.62595 9.69586 10 8.63551 10H5.15148H1.36449C0.304137 10 -0.301781 8.77863 0.152657 7.70992L1.97041 4.19847L3.93964 0.687023Z",
  expert:
    "M3.93964 0.687023C4.39408 -0.229008 5.7574 -0.229008 6.21184 0.687023L8.02959 4.19847L9.84734 7.70992C10.3018 8.62595 9.69586 10 8.63551 10H5.15148H1.36449C0.304137 10 -0.301781 8.77863 0.152657 7.70992L1.97041 4.19847L3.93964 0.687023Z",
  onderzoeker:
    "M3.93964 0.687023C4.39408 -0.229008 5.7574 -0.229008 6.21184 0.687023L8.02959 4.19847L9.84734 7.70992C10.3018 8.62595 9.69586 10 8.63551 10H5.15148H1.36449C0.304137 10 -0.301781 8.77863 0.152657 7.70992L1.97041 4.19847L3.93964 0.687023Z",
  gebiedsmanager:
    "M9.40298 3.73134C8.95522 3.73134 8.65672 3.13432 8.95522 2.83582C9.10448 2.68656 9.10448 2.2388 8.95522 2.08955L7.91045 1.04478C7.76119 0.895523 7.31343 0.895523 7.16418 1.04478C6.86567 1.34328 6.26866 1.19403 6.26866 0.597011C6.26866 0.298504 5.97015 0 5.67164 0H4.32836C4.02985 0 3.73134 0.298504 3.73134 0.597011C3.73134 1.04477 3.13433 1.34328 2.83582 1.04478C2.68657 0.895523 2.23881 0.895523 2.08955 1.04478L1.04478 2.08955C0.895522 2.2388 0.895522 2.68656 1.04478 2.83582C1.34328 3.13432 1.19403 3.73134 0.597015 3.73134C0.298507 3.73134 0 4.02985 0 4.32835V5.67164C0 5.97015 0.298507 6.26866 0.597015 6.26866C1.04478 6.26866 1.34328 6.86567 1.04478 7.16418C0.895522 7.31343 0.895522 7.76119 1.04478 7.91045L2.08955 8.95522C2.23881 9.10448 2.68657 9.10448 2.83582 8.95522C3.13433 8.65672 3.73134 8.80597 3.73134 9.40298C3.73134 9.70149 4.02985 10 4.32836 10H5.67164C5.97015 10 6.26866 9.70149 6.26866 9.40298C6.26866 8.95522 6.86567 8.65672 7.16418 8.95522C7.31343 9.10448 7.76119 9.10448 7.91045 8.95522L8.95522 7.91045C9.10448 7.76119 9.10448 7.31343 8.95522 7.16418C8.65672 6.86567 8.80597 6.26866 9.40298 6.26866C9.70149 6.26866 10 5.97015 10 5.67164V4.32835C10 4.02985 9.70149 3.73134 9.40298 3.73134Z",
  programmamanager:
    "M9.40298 3.73134C8.95522 3.73134 8.65672 3.13432 8.95522 2.83582C9.10448 2.68656 9.10448 2.2388 8.95522 2.08955L7.91045 1.04478C7.76119 0.895523 7.31343 0.895523 7.16418 1.04478C6.86567 1.34328 6.26866 1.19403 6.26866 0.597011C6.26866 0.298504 5.97015 0 5.67164 0H4.32836C4.02985 0 3.73134 0.298504 3.73134 0.597011C3.73134 1.04477 3.13433 1.34328 2.83582 1.04478C2.68657 0.895523 2.23881 0.895523 2.08955 1.04478L1.04478 2.08955C0.895522 2.2388 0.895522 2.68656 1.04478 2.83582C1.34328 3.13432 1.19403 3.73134 0.597015 3.73134C0.298507 3.73134 0 4.02985 0 4.32835V5.67164C0 5.97015 0.298507 6.26866 0.597015 6.26866C1.04478 6.26866 1.34328 6.86567 1.04478 7.16418C0.895522 7.31343 0.895522 7.76119 1.04478 7.91045L2.08955 8.95522C2.23881 9.10448 2.68657 9.10448 2.83582 8.95522C3.13433 8.65672 3.73134 8.80597 3.73134 9.40298C3.73134 9.70149 4.02985 10 4.32836 10H5.67164C5.97015 10 6.26866 9.70149 6.26866 9.40298C6.26866 8.95522 6.86567 8.65672 7.16418 8.95522C7.31343 9.10448 7.76119 9.10448 7.91045 8.95522L8.95522 7.91045C9.10448 7.76119 9.10448 7.31343 8.95522 7.16418C8.65672 6.86567 8.80597 6.26866 9.40298 6.26866C9.70149 6.26866 10 5.97015 10 5.67164V4.32835C10 4.02985 9.70149 3.73134 9.40298 3.73134Z",
  lagereoverheid:
    "M10 6.26866V3.73134C10 3.43283 9.70149 3.13433 9.40298 3.13433H7.46269C7.16418 3.13433 6.86567 2.83582 6.86567 2.53731V0.597011C6.86567 0.298504 6.56716 0 6.26866 0H3.73134C3.43284 0 3.13433 0.298504 3.13433 0.597011V2.53731C3.13433 2.83582 2.83582 3.13433 2.53731 3.13433H0.597015C0.298507 3.13433 0 3.43283 0 3.73134V6.26866C0 6.56716 0.298507 6.86567 0.597015 6.86567H2.53731C2.83582 6.86567 3.13433 7.16417 3.13433 7.46268V9.40298C3.13433 9.70149 3.43284 10 3.73134 10H6.26866C6.56716 10 6.86567 9.70149 6.86567 9.40298V7.46268C6.86567 7.16417 7.16418 6.86567 7.46269 6.86567H9.40298C9.70149 6.86567 10 6.56716 10 6.26866Z",
  lokaleoverheid:
    "M10 6.26866V3.73134C10 3.43283 9.70149 3.13433 9.40298 3.13433H7.46269C7.16418 3.13433 6.86567 2.83582 6.86567 2.53731V0.597011C6.86567 0.298504 6.56716 0 6.26866 0H3.73134C3.43284 0 3.13433 0.298504 3.13433 0.597011V2.53731C3.13433 2.83582 2.83582 3.13433 2.53731 3.13433H0.597015C0.298507 3.13433 0 3.43283 0 3.73134V6.26866C0 6.56716 0.298507 6.86567 0.597015 6.86567H2.53731C2.83582 6.86567 3.13433 7.16417 3.13433 7.46268V9.40298C3.13433 9.70149 3.43284 10 3.73134 10H6.26866C6.56716 10 6.86567 9.70149 6.86567 9.40298V7.46268C6.86567 7.16417 7.16418 6.86567 7.46269 6.86567H9.40298C9.70149 6.86567 10 6.56716 10 6.26866Z",
  manager:
    "M10 7.12121V2.87879C8.33333 2.87879 7.12121 1.51515 7.12121 0H2.87879C2.87879 1.66667 1.51515 2.87879 0 2.87879V7.12121C1.66667 7.12121 2.87879 8.48485 2.87879 10H7.12121C6.9697 8.48485 8.33333 7.12121 10 7.12121Z",
  ondernemer:
    "M5 10C7.76142 10 10 7.76142 10 5C10 2.23857 7.76142 0 5 0C2.23858 0 0 2.23857 0 5C0 7.76142 2.23858 10 5 10Z",
  projectleider:
    "M10 7.38462V2.92308C10 2.15385 9.69697 1.38462 9.24242 0.923078C8.63636 0.307693 8.0303 0 7.27273 0H2.72727C1.9697 0 1.36364 0.307692 0.757576 0.769231C0.30303 1.23077 0 2 0 2.76923V7.23077C0 8 0.30303 8.76923 0.757576 9.23077C1.21212 9.69231 1.9697 10 2.72727 10H7.27273C8.0303 10 8.63636 9.69231 9.24242 9.23077C9.69697 8.76923 10 8 10 7.38462Z",
  experiment:
    "M5,1.3l1.3,2.3l0,0.1l0.1,0L8.7,5L6.4,6.3l-0.1,0l0,0.1L5,8.7L3.7,6.4l0-0.1l-0.1,0L1.3,5l2.3-1.3l0.1,0l0-0.1L5,1.3z",
  ontwikkeltraject:
    "M11.314 0L16 4.68597V11.314L11.314 16H4.68597L0 11.314V4.68597L4.68597 0H11.314Z",
  clickaction: "M1 7L3 4L1 1",
};

function circlePath(cx, cy, r) {
  return (
    "M " +
    cx +
    " " +
    cy +
    " m -" +
    r +
    ", 0 a " +
    r +
    "," +
    r +
    " 0 1,0 " +
    r * 2 +
    ",0 a " +
    r +
    "," +
    r +
    " 0 1,0 -" +
    r * 2 +
    ",0"
  );
}

/* SCALE MAP NL*/
export const scaleMapAndGebiedsAreas = (svg, windowSize, zoomed) => {
  // LET OP, DEZE WAARDEN STAAN OP 3 PLEKKEN
  const { width, height } = windowSize;
  const nlWidth = 568,
    nlHeight = 673, //it's a given in the SVG
    margin = height * 0.15, //TODO dit ergens vaststellen? Wordt ook gebruikt bij coordinate-functions.js: calculateExperimenteergebiedCoordinates()
    marginRight = width * 0.1; //TODO dit ergens vaststellen?
  const ratio = (height - margin) / nlHeight;
  const translateX = width - nlWidth * ratio - marginRight;

  svg
    .select("g.map-nl")
    .attr(
      "transform",
      (d) => `translate(${translateX},${margin / 2}) scale(${ratio})`
    )
    .attr("style", `opacity:${zoomed ? 0.4 : 1} `)
    .selectAll("path")
    .transition()
    .delay((d, i) => {
      return i * 30;
    })
    .attr("style", `opacity: 1 `);
};

/* RELATIONS */
const linkGenerator = linkHorizontal()
  .source(function (d) {
    return [d.source.x, d.source.y];
  })
  .target(function (d) {
    return [d.target.x, d.target.y];
  });

export const renderRelations = (svg, relations) => {
  svg
    .select(".relations")
    .selectAll("path")
    .data(relations)
    .join(
      (enter) => {
        enter
          .append("path")
          .attr("opacity", 0)
          .transition()
          // .delay((d, i) => i * 20)
          .attr("d", linkGenerator)
          .attr("opacity", 1);
      },
      (update) => {
        update.transition().attr("d", linkGenerator);
      }
    );
  // .join("path")
  // .attr("opacity", 0)
  // .transition()
  // .attr("d", linkGenerator)
  // .attr("opacity", 1);
};
/* BADGES */
export const renderBadges = (svg, areas, zoomed) => {
  let badges = [];
  for (let i = 0; i < areas.length; i++) {
    if (areas[i].badges.length) {
      badges = badges.concat(areas[i].badges);
    }
  }
  const badgeG = svg
    .select("g.ontwikkelbadges")
    .selectAll(".badge")
    .data(badges, (d) => {
      return d.id;
    })
    .join(
      (enter) => {
        const enterBadgeG = enter
          .append("g")
          .attr(
            "transform",
            (d) => `translate(${d.position.x - 2},${d.position.y - 2})`
          )
          .attr("class", (d) => `badge ${d.title}`);
        enterBadgeG
          .append("path")
          .attr("class", "symbol")
          .attr("d", symbolPaths.ontwikkeltraject);

        enterBadgeG
          .append("text")
          .attr("class", "badgetitle")
          .attr("x", 20)
          .attr("dy", 12)
          .text((d) => (zoomed ? d.formattedTitle : ""));

        return enterBadgeG;
      },
      (update) => {
        update.call((update) => {
          const updateBadgeG = update
            .transition()
            .attr(
              "transform",
              (d) => `translate(${d.position.x - 2},${d.position.y - 2})`
            );
          updateBadgeG
            .select("text")
            .text((d) => (zoomed ? d.formattedTitle : ""));
          updateBadgeG
            .select(".symbol")
            .attr("transform", (d) =>
              zoomed ? `translate(-18,-10) scale(2)` : ""
            );

          return updateBadgeG;
        });
      }
    );
};

/* EXPERIMENTEER GEBIEDEN */
const getAreaAttributes = (attribute, value, viewType, windowSize) => {
  switch (attribute) {
    case "r":
      return viewType === "zoom" ? value.position.r * 2 : value.position.r;
      return viewType === "zoom" ? windowSize.height / 5 : value.position.r;
    case "x":
      return value.position.x;
      return viewType === "zoom"
        ? (windowSize.width / 5) * 4
        : value.position.x;
    case "y":
      return value.position.y;
      return viewType === "zoom" ? windowSize.height / 2 : value.position.y;
  }
};
export const renderExperimenteergebieden = (
  svg,
  data,
  nodeClickHandler,
  viewType = "overview",
  windowSize = {},
  zoomedExperimenteerGebied,
  stagger = false
) => {
  svg
    .select("g.experimenteerGebieden")
    .selectAll("circle.area")
    .data(data, (d) => d.id) // "A key function may be specified to control which datum is assigned to which element, replacing the default join-by-index, by computing a string identifier for each datum and element. " https://github.com/d3/d3-selection#joining-data
    .join(
      (enter) => {
        const enterG = enter
          .append("circle")
          .attr("r", (value) =>
            getAreaAttributes("r", value, viewType, windowSize)
          )
          .attr("cx", (value) =>
            getAreaAttributes("x", value, viewType, windowSize)
          )
          .attr("cy", (value) =>
            getAreaAttributes("y", value, viewType, windowSize)
          );
        enterG
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => (stagger ? i * 50 : 0))
          .attr("opacity", 1);
        return enterG;
      },

      (update) => {
        return update.call((update) =>
          update
            .transition()
            .duration(300)
            .delay(zoomedExperimenteerGebied ? 200 : 0)
            .attr("r", (value) =>
              getAreaAttributes("r", value, viewType, windowSize)
            )
            .attr("cx", (value) =>
              getAreaAttributes("x", value, viewType, windowSize)
            )
            .attr("cy", (value) =>
              getAreaAttributes("y", value, viewType, windowSize)
            )
            .attr("style", (d) =>
              d.id === zoomedExperimenteerGebied
                ? "opacity: 0.2"
                : "opacity: 01"
            )
        );
      }
    )
    .attr("class", "area")
    .on("click", (e, d) => {
      nodeClickHandler(d.id);
    });

  if (viewType === "overview") {
    svg
      .select("g.experimenteerGebieden")
      .selectAll("circle.centerpoint")
      .data(data, (d) => `centerpoint-${d.id}`)
      .join("circle")
      .attr("class", "centerpoint")
      .attr("r", 6)

      .attr("cx", (value) => getAreaAttributes("x", value))
      .attr("cy", (value) => getAreaAttributes("y", value))
      .attr("style", "opacity: 1")
      .on("click", (e, d) => {
        nodeClickHandler(d.id);
      });
  } else {
    svg
      .select("g.experimenteerGebieden")
      .selectAll("circle.centerpoint")
      .attr("style", (d) =>
        d.id === zoomedExperimenteerGebied ? "opacity: 0.2" : "opacity: 0"
      );
  }
};

/* NODES */
const getNodeGPosition = (value) => {
  return `translate(${value.position.x + 15},${value.position.y})`; // TODO moet 13 hier fixed zijn?
};
const getNodeText = (d) => {
  if (d.type === "experiment") {
    return d.last_name;
  }
  return `${d.first_name} ${d.last_name}`;
};

export const renderNodes = (
  svg,
  data,
  nodeClickHandler,
  zoomed,
  side,
  stagger = false
) => {
  svg
    .select("g.nodes")
    .selectAll(`g.${side}`)
    .data(data, (d) => d.id)
    .join(
      (enter) => {
        const enterNodeG = enter
          .append("g")
          .attr("class", (d) => `node ${side} ${d.type}`)
          .attr("transform", (value, i) => getNodeGPosition(value));

        enterNodeG
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => {
            if (stagger) {
              if (zoomed) {
                return (side === "right" ? 200 : 100) + i * 25;
              }
              return i * 25;
            } else {
              return 0;
            }
          })
          .attr("opacity", 1);
        enterNodeG
          .append("path")
          // .attr("transform", "translate(-6,-5) scale(1.5)")

          .attr("d", (d) => {
            switch (d.type) {
              case "experiment":
                return symbolPaths.experiment;

              case "person":
                return symbolPaths[d.role];

              case "gremium":
                return circlePath(6, 6, 6);
              default:
                return "";
            }
          })
          .attr("class", (d) => `symbol ${d.type} ${zoomed ? "zoomed" : null}`);
        enterNodeG
          .append("text")
          .attr("class", "name")
          .attr("x", (d) => {
            if (zoomed) {
              return 30;
            }
            return d.type !== "info" ? 15 : 0;
          })
          .attr("dy", 3)
          .append("tspan")
          .attr(
            "class",
            (d) => `${d.type} ${d.is_contactperson ? "primarycontact" : ""}`
          )
          .text(getNodeText);
        // .append("tspan")
        // .attr("class", "clickaction")
        // .text(" >");

        return enterNodeG;
      },
      (update) =>
        update.call((update) => {
          const updatePersonG = update
            .transition()
            .attr("transform", (value, i) => getNodeGPosition(value));

          updatePersonG.select("text").text(getNodeText);

          // update.selectAll(".functionTitle").remove();

          // if (visualizationData.type === "zoom") {
          //   update
          //     .select("text")
          //     .append("tspan")
          //     .attr("x", 10)
          //     .attr("dy", 15)
          //     .attr("class", "functionTitle")
          //     .text("funtieomschrijving");
          // }
          updatePersonG.select("text.name").attr("x", (d) => {
            if (zoomed) {
              return 30;
            }
            return d.type !== "info" ? 15 : 0;
          });
          updatePersonG
            .select(".symbol")
            .attr(
              "class",
              (d) => `symbol ${d.type} ${zoomed ? "zoomed" : null}`
            );

          return updatePersonG;
        }),
      (exit) => exit.remove()
    )
    .on("click", (i, d) => {
      if (d.type === "experiment" && undefined !== d.areaId) {
        nodeClickHandler({ type: "area", id: d.areaId });
        return;
      }
      if (d.type !== "info") {
        nodeClickHandler({ type: "node", id: d.id });
      } else {
        nodeClickHandler({ type: "area", id: d.areaId });
      }
    });
};

export const renderDirecties = (svg, data) => {
  svg
    .select("g.directies")
    .selectAll(".directie")
    .data(data, (d) => d.id)
    .join((enter) => {
      enter
        .append("text")
        .attr("opacity", 0)
        .attr("class", "directie")
        .attr("x", (d, i) => d.position.x - 25) // TODO Afhankelijk van fontsize (en windowsize misschien)
        .attr("y", (d, i) => d.position.y + 4) // TODO Afhankelijk van fontsize
        .text((d) => {
          return `${d.first_name} ${d.last_name}`;
        })
        .transition()
        .delay((d, i) => i * 100)
        .attr("opacity", 1);
    });
};
export const renderKKL = (svg, data, nodeClickHandler) => {
  //TODO het werkt om de andere functie aan te roepen, maar hij zet geen data?!
  renderOntwikkelTrajecten(svg, data, nodeClickHandler, {
    g: "kkl",
    subg: "kklarea",
  });
};

export const renderOntwikkelTrajecten = (
  svg,
  data,
  ontwikkelTrajectClickHandler,
  target = { g: "ontwikkeltrajecten", subg: "ontwikkeltraject" },
  stagger = false
) => {
  const fontsize = 14; //TODO dit moet ergens variabel?
  svg
    .select(`g.${target.g}`)
    .selectAll(`g.${target.subg}`)
    .data(data, (d) => {
      return d.id;
    })

    .join(
      (enter) => {
        const enterAreaG = enter
          .append("g")
          .attr(
            "class",
            (d) => `${target.subg} ${stripToSaveFromName(d.title)}`
          )
          .attr(
            "transform",
            (value, i) =>
              `translate(${value.position.x},${
                value.position.y - fontsize / 2 // center on Y coorindate
              })`
          );

        enterAreaG
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => {
            if (stagger) {
              return i * 50;
            }
            return 0;
          })
          .attr("opacity", 1);

        enterAreaG
          .append("path")
          .attr("class", "symbol")
          .attr("transform", "translate(-15,-10)")
          .attr("d", (d) => {
            return symbolPaths["ontwikkeltraject"];
          });
        enterAreaG
          .append("text")
          .attr("x", (d) => (d.type !== "info" ? 10 : 0))
          .attr("dy", 3)
          .append("tspan")
          .attr("class", (d) => `${d.type}`)
          .attr("style", `font-size: ${fontsize}px`)
          .text((d) => {
            return `${d.title}`;
          });

        return enterAreaG;
      },
      (update) =>
        update.call((update) => {
          const updateAreaG = update
            .transition()
            .attr(
              "transform",
              (value, i) => `translate(${value.position.x},${value.position.y})`
            );
          return updateAreaG;
        }),
      (exit) => exit.remove()
    )
    .on("click", (e, d) => {
      ontwikkelTrajectClickHandler(d.id);
    });
};
