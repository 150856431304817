import { useEffect, useState } from "react";
import {
  atom_zoomedOntwikkeltraject,
  atom_areas,
  atom_modal_state,
  atom_reloadNodesTrigger,
  atom_currently_selected_ontwikkeltraject,
} from "../../recoil/atoms";
import { motion } from "framer-motion";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import arrow_back from "../../img/icon_arrow_back.svg";
import icon_plus from "../../img/icon_plus.svg";
import { stripToSaveFromName, nl2br } from "../../helpers/string-functions";
import { Icon, Form } from "semantic-ui-react";
import { directus_updateArea } from "../../services/directus/helpers";
const LeftWindow = ({ globalUserInfo }) => {
  const [selectedAreaId, setSelectedArea] = useRecoilState(
    atom_zoomedOntwikkeltraject
  );
  const areas = useRecoilValue(atom_areas);
  const setModalState = useSetRecoilState(atom_modal_state);
  const setCurrentlySelectedOntwikkeltraject = useSetRecoilState(
    atom_currently_selected_ontwikkeltraject
  );

  const [area, setArea] = useState({ title: "" });
  const [editMode, setEditMode] = useState(false);
  const [editedArea, setEditedArea] = useState(area);
  const [userDidEdit, setUserDidEdit] = useState(false);
  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);

  useEffect(() => {
    setArea(areas.find((a) => a.id === selectedAreaId));
  }, [selectedAreaId]);

  if (!selectedAreaId) {
    return (
      <div className="left">
        <h2>Ontwikkeltrajecten</h2>
      </div>
    );
  }
  const saveArea = async () => {
    const response = await directus_updateArea(area.id, {
      description: editedArea.description,
    });
    if (response) {
      setEditMode(false);
      setReloadNodesTrigger(Date.now());
      setArea({ ...area, description: editedArea.description });
    }
  };
  const onInputChange = (e, { name, value, checked, type }) => {
    switch (type) {
      case "checkbox":
        setEditedArea({ ...editedArea, [name]: checked });

        break;
      default:
        setEditedArea({ ...editedArea, [name]: value });
    }

    setUserDidEdit(true);
  };
  const openBadgeModal = (id) => {
    setCurrentlySelectedOntwikkeltraject(id);
    setModalState({
      mode: "view",
      type: "ontwikkeltraject",
    });
  };

  const renderHeader = () => {
    return (
      <>
        <h1
          className={stripToSaveFromName(area ? area.title : "")}
          onClick={() => openBadgeModal(area.id)}
        >
          <motion.svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <path
              d="M11.314 0L16 4.68597V11.314L11.314 16H4.68597L0 11.314V4.68597L4.68597 0H11.314Z"
              fill="#FE7342"
            />
          </motion.svg>
          {area ? area.title : ""}{" "}
        </h1>
        {editMode ? (
          <Form>
            <Form.TextArea
              name="description"
              value={editedArea.description}
              onChange={onInputChange}
            />
            <Icon
              name="checkmark"
              color={userDidEdit ? "green" : null}
              onClick={() => saveArea()}
            />
            <Icon
              name="cancel"
              color="red"
              onClick={() => {
                setEditMode(false);
              }}
            />
          </Form>
        ) : (
          <div
            className="description"
            onClick={() => {
              if (globalUserInfo !== 0 && globalUserInfo.role !== "user") {
                setEditedArea(area);
                setEditMode(true);
              }
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: area ? nl2br(area.description) : "",
              }}
            />

            {globalUserInfo !== 0 && globalUserInfo.role !== "user" ? (
              <div className="edit">
                <Icon name="edit outline" />
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  };
  return (
    <div className="left">
      <motion.div
        key="ui-line-left"
        className="ui-line"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{
          opacity: 0,
          transition: { duration: 0.2 },
        }}
      >
        {globalUserInfo !== 0 && globalUserInfo.role !== "user" ? (
          <div className="buttonGroupBottom">
            <div
              className="button"
              onClick={() => {
                setModalState({
                  mode: "create",
                  type: "person",
                  area: area,
                });
              }}
            >
              <img src={icon_plus} />
              Persoon toevoegen
            </div>
            <div
              className="button"
              onClick={() => {
                setModalState({
                  mode: "create",
                  type: "gremium",
                  area: area,
                });
              }}
            >
              <img src={icon_plus} />
              Groep toevoegen
            </div>
          </div>
        ) : null}
      </motion.div>
      <h2
        className="active"
        onClick={() => {
          setSelectedArea(false);
        }}
      >
        <img src={arrow_back} className="arrow" />
        Ontwikkeltrajecten
      </h2>
      {renderHeader()}
    </div>
  );
};
export default LeftWindow;
