import DirectusSDK from "@directus/sdk-js";

// export default new DirectusSDK("http://localhost:8055", {
export default new DirectusSDK("https://api.ontwikkeltrajecten-kll.nl/", {
  auth: {
    storage: localStorage, // Storage adapter where refresh tokens are stored in JSON mode
    mode: "json", // What login mode to use. One of `json`, `cookie`
    autoRefresh: true, // Whether or not to automatically refresh the access token on login
  },
});
