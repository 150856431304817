import { Icon, Button } from "semantic-ui-react";
import { useRecoilValue } from "recoil";
import { atom_userInfo } from "../../../recoil/atoms";
const EditBar = ({
  editMode,
  setEditMode,
  errorMessage,
  userDidEdit,
  saveChanges,
}) => {
  const globalUserInfo = useRecoilValue(atom_userInfo);

  if (globalUserInfo === 0 || globalUserInfo.role === "user") {
    return null;
  }
  return (
    <div className="editBar">
      {editMode ? (
        <>
          {errorMessage != null ? (
            <span style={{ marginRight: "1em" }}>{errorMessage}</span>
          ) : null}

          <Button
            disabled={!userDidEdit}
            onClick={() => {
              saveChanges();
            }}
          >
            {userDidEdit ? "Save" : "nog geen aanpassingen..."}
          </Button>
          <Button
            onClick={() => {
              setEditMode(false);
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Icon
          name="edit outline"
          onClick={() => {
            setEditMode(!editMode);
          }}
        />
      )}
    </div>
  );
};
export default EditBar;
