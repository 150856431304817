import RightWindow from "./userwindows/right";
import LeftWindow from "./userwindows/left";
import { atom_userInfo } from "../recoil/atoms";
import { useRecoilValue } from "recoil";
import { Suspense } from "react";
import { motion } from "framer-motion";
const UserWindows = () => {
  const globalUserInfo = useRecoilValue(atom_userInfo);

  return (
    <motion.div
      className="ui"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 2.5, delay: 1 } }}
    >
      <LeftWindow globalUserInfo={globalUserInfo} />
      <Suspense fallback="">
        <RightWindow globalUserInfo={globalUserInfo} />
      </Suspense>
    </motion.div>
  );
};
export default UserWindows;
