import { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Icon,
  Message,
  Grid,
} from "semantic-ui-react";
import { useRecoilStateLoadable } from "recoil";
import { atom_userInfo } from "../recoil/atoms";
import {
  directus_getUserInfo,
  directus_loginUser,
} from "../services/directus/helpers";

const IntroScreen = () => {
  const [globalUserInfo, setGlobalUserInfo] = useRecoilStateLoadable(
    atom_userInfo
  );

  const initialLoginFormData = { email: "", password: "" };
  const [loginFormData, setLoginFormData] = useState(initialLoginFormData);
  const [loginError, setLoginError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const [loggedIn, setLoggedIn] = useState(
    globalUserInfo.state == "hasValue" && globalUserInfo.contents !== 0
      ? true
      : false
  );

  const onFormChange = (target) => {
    setLoginError(false);
    setLoginFormData({ ...loginFormData, [target.name]: target.value });
  };
  const login = async () => {
    const loginResponse = await directus_loginUser(
      loginFormData.email,
      loginFormData.password
    );
    setLoggingIn(false);

    if (loginResponse) {
      const loadedUserInfo = await directus_getUserInfo();
      setGlobalUserInfo(loadedUserInfo);
      setLoggedIn(true);
    } else {
      setLoginError(true);
    }
  };
  return (
    <div className="introScreen">
      <div>
        <p>
          Welkom bij de interactieve Ontwikkeltrajectkaart Experimenteergebieden
          Kringlooplandbouw. Zodra je bent ingelogd, kun je via enkele
          muisklikken navigeren door de diverse ontwikkeltrajecten en
          experimenten waaraan de experimenteergebieden kringlooplandbouw en het
          Ministerie van LNV samen werken. Door met het punt op de tijdlijn
          onderaan de pagina te schuiven, kun je bovendien de ontwikkeling tot
          nu toe en in de nabije toekomst in beeld brengen. Veel plezier!
        </p>
        <p>
          Deze visualisatie wordt beheerd door het projectteam
          Experimenteergebieden Kringlooplandbouw. Voor vragen of opmerkingen,
          kun je contact opnemen met het projectteam via:{" "}
          <a href="mailto:experimenteergebieden-kll@minlnv.nl">
            experimenteergebieden-kll@minlnv.nl
          </a>
        </p>
      </div>
      <div>
        <Form>
          <Form.Input
            type="text"
            placeholder="E-mail adres"
            name="email"
            onChange={(e) => {
              onFormChange(e.target);
            }}
          />
          <Form.Input
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="current-password"
            onChange={(e) => {
              onFormChange(e.target);
            }}
          />
          {loginError ? (
            <Message color="yellow">E-mail en/of password onjuist.</Message>
          ) : null}
          <Button className="login" onClick={() => login()}>
            <Icon name="unlock alternate" /> Login
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default IntroScreen;
