import { useState } from "react";
import {
  atom_userInfo,
  atom_reloadNodesTrigger,
  atom_modal_state,
} from "../../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Icon } from "semantic-ui-react";
import { directus_deleteNode } from "../../../services/directus/helpers";

const ModalDeleteNode = ({ node }) => {
  const globalUserInfo = useRecoilValue(atom_userInfo);
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);
  const setModalState = useSetRecoilState(atom_modal_state);

  if (globalUserInfo === 0 || globalUserInfo.role === "user") {
    return null;
  }
  return (
    <>
      <section className="divider"></section>
      <section className={`removeBar ${deleting ? "disabled" : ""}`}>
        {showAreYouSure ? (
          <>
            Weet je het zeker?
            <span
              className="button"
              onClick={async () => {
                setDeleting(true);
                if (await directus_deleteNode(node)) {
                  setReloadNodesTrigger(Date.now());
                  setModalState({ mode: "hidden" });
                } else {
                  setDeleting(false);
                  setErrorMessage("Er ging iets mis.");
                }
              }}
            >
              <Icon name="check" color="green" />
              Ja, verwijderen
            </span>
            <span
              className="button"
              onClick={() => {
                if (!deleting) {
                  setErrorMessage(false);
                  setShowAreYouSure(false);
                }
              }}
            >
              <Icon name="remove" color="red" />
              Annuleren
            </span>
            {errorMessage ? errorMessage : ""}
          </>
        ) : (
          <span
            className="button"
            onClick={() => {
              setShowAreYouSure(true);
            }}
          >
            <Icon name="trash alternate outline" color="red" />
            Verwijderen
          </span>
        )}
      </section>
    </>
  );
};
export default ModalDeleteNode;
