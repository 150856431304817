import Tag from "./tag";
import { useEffect, useState } from "react";
import { directus_updateNode } from "../../../services/directus/helpers";
import { Form } from "semantic-ui-react";
import { atom_themes, atom_userInfo } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import EditBar from "./editBar";
import { directus_updateNodeThemes } from "../../../services/directus/helpers";

const ModalRelatedThemes = ({ node, updateNode, editOnly = false }) => {
  const [editMode, setEditMode] = useState(editOnly);
  const [editedNode, setEditedNode] = useState(
    node.themes ? node : { ...node, themes: [] }
  );
  const [userDidEdit, setUserDidEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const availableThemes = useRecoilValue(atom_themes);
  const globalUserInfo = useRecoilValue(atom_userInfo);

  const saveChanges = async () => {
    const updateResponse = await directus_updateNodeThemes(
      node,
      editedNode.themes.map((theme) => theme.themes_id)
    );

    if (updateResponse) {
      setEditMode(false);
      updateNode(editedNode);
      setUserDidEdit(false);
    } else {
      setErrorMessage("Er ging iets mis...");
    }
  };

  const onChangeSelection = (e, { name, checked }) => {
    if (checked) {
      setEditedNode({
        ...editedNode,
        themes: editedNode.themes.concat({ themes_id: name }),
      });
    } else {
      setEditedNode({
        ...editedNode,
        themes: editedNode.themes.filter((t) => t.themes_id !== name),
      });
    }
    setUserDidEdit(true);

    return true;
  };
  const renderContent = () => {
    if (editMode) {
      return (
        <div className="columns list">
          {availableThemes.map((theme) => {
            return (
              <div class="theme">
                <Form.Checkbox
                  key={`check-node-${node.id}-theme-${theme.id}`}
                  label={`${theme.label} `}
                  name={theme.id}
                  onChange={onChangeSelection}
                  checked={
                    editedNode.themes &&
                    editedNode.themes.some((t) => {
                      return t.themes_id === theme.id;
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      );
    }
    if (node.themes && node.themes.length > 0) {
      return (
        <>
          {node.themes.map((theme, index) => {
            const themeObj = availableThemes.filter(
              (t) => t.id === theme.themes_id
            );
            return <Tag key={`theme-tag-${index}`}>{themeObj[0].label}</Tag>;
          })}
        </>
      );
    }
  };

  if (
    node.themes === null &&
    (globalUserInfo === 0 || globalUserInfo.role === "user")
  ) {
    return null;
  }

  return (
    <>
      <section className="divider"></section>
      <section className={`themes  ${editMode ? "editing" : ""}`}>
        <div className={`${editMode ? "" : "inlinecolumns"}`}>
          <h6>Gerelateerde thema's</h6>
          <div>{renderContent()}</div>
        </div>
        {!editOnly && (
          <EditBar
            editMode={editMode}
            setEditMode={setEditMode}
            saveChanges={saveChanges}
            errorMessage={errorMessage}
            userDidEdit={userDidEdit}
          />
        )}
      </section>
    </>
  );
};

export default ModalRelatedThemes;
