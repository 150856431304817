import { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import {
  atom_modal_state,
  atom_currently_selected_node,
  atom_reloadNodesTrigger,
} from "../../recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import ModalHeader from "./modalComponents/modalHeader";
import ModalVisibility from "./modalComponents/ModalVisibility";
import { directus_createNode } from "../../services/directus/helpers";

const CreateDetailModal = () => {
  const setSelectedNodeId = useSetRecoilState(atom_currently_selected_node);
  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);

  const [modalState, setModalState] = useRecoilState(atom_modal_state);
  const [formSubmitted, setFormsubmitted] = useState(false);
  const [node, setNode] = useState({
    first_name: modalState.type === "experiment" ? "Experiment" : "",
    last_name: "",
    role: "",
    area: modalState.area,
    always_visible: true,
    visible_start: null,
    visible_end: null,
    type: modalState.type,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const updateNode = (updatedItems) => {
    setNode({ ...node.content, ...updatedItems });
  };

  const hideModal = () => {
    setModalState({ mode: "hidden" });
  };
  const createNode = async () => {
    const objectKeys = Object.keys(node);
    setFormsubmitted(true);
    for (let i = 0; i < objectKeys.length; i++) {
      if (node.type === "experiment") {
        switch (objectKeys[i]) {
          case "last_name":
          case "role":
            continue; // last_name not required for experiment

          default:
        }
      }
      if (node.type === "gremium") {
        switch (objectKeys[i]) {
          case "role":
            continue; // last_name not required for experiment

          default:
        }
      }
      if (node[objectKeys[i]] === "") {
        return false;
      }
    }
    try {
      node.area = node.area.id;

      const newNode = await directus_createNode(node);

      setSelectedNodeId(newNode.id);
      setModalState({
        type: newNode.type,
        mode: "view",
      });
      setReloadNodesTrigger(Date.now());
    } catch {
      setErrorMessage("Er ging iets mis...");
      setFormsubmitted(true);
    }
  };

  return (
    <>
      <ModalHeader
        node={node}
        updateNode={updateNode}
        editOnly={true}
        showValidationErrors={formSubmitted}
      />

      <ModalVisibility node={node} updateNode={updateNode} editOnly={true} />
      <section>
        <div className="editBar dontHide alignLeft">
          {errorMessage != null ? errorMessage : null}

          <Form.Button
            onClick={() => {
              createNode();
            }}
          >
            Aanmaken
          </Form.Button>
          <Form.Button
            onClick={() => {
              hideModal();
            }}
          >
            Annuleren
          </Form.Button>
        </div>
      </section>
    </>
  );
};
export default CreateDetailModal;
