import PersonCard from "./personCard";
import NonPersonCard from "./nonPersonCard";
import Tag from "./tag";
import { Loader, Form } from "semantic-ui-react";
import { useEffect, useState } from "react";
import {
  directus_getNodeRelations,
  directus_updateNode,
  directus_updateNodeRelations,
} from "../../../services/directus/helpers";
import EditBar from "./editBar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { atom_userInfo, atom_reloadNodesTrigger } from "../../../recoil/atoms";

import { selector_all_people_by_type } from "../../../recoil/selectors";
import { filter } from "d3";
import { render } from "react-dom";

const ModalRelations = ({ nodeId }) => {
  const all_people = useRecoilValue(selector_all_people_by_type);
  const [editMode, setEditMode] = useState(false);
  const [relations, setRelations] = useState({
    state: "loading",
    content: [],
  });
  const [userDidEdit, setUserDidEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [stagedRelations, setStagedRelations] = useState([]);
  const globalUserInfo = useRecoilValue(atom_userInfo);
  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);

  const filteredPeople = all_people.filter((person) => {
    if (searchString === null) {
      return true;
    }

    return (
      person.first_name.toLowerCase().includes(searchString) ||
      person.last_name.toLowerCase().includes(searchString)
    );
  });

  useEffect(() => {
    (async () => {
      let loadedRelations = await directus_getNodeRelations(nodeId);

      loadedRelations = loadedRelations.filter((relation) => {
        return relation !== undefined;
      });

      let relationsObject = loadedRelations.map((relation) => {
        const relationId =
          relation.from === nodeId ? relation.to : relation.from;
        const filteredResults = all_people.filter((p) => {
          return p.id === relationId;
        });
        return filteredResults[0];
      });

      relationsObject = relationsObject.sort((a, b) => {
        let aLastName = a.last_name.replace("van ", "");
        aLastName = aLastName.replace("de ", "");
        aLastName = aLastName.replace("der ", "");
        let bLastName = b.last_name.replace("van ", "");
        bLastName = bLastName.replace("de ", "");
        bLastName = bLastName.replace("der ", "");
        console.log(aLastName, bLastName);
        if (aLastName < bLastName) {
          return -1;
        }
        if (aLastName > bLastName) {
          return 1;
        }
        return 0;
      });

      relationsObject = relationsObject.filter((relation) => {
        return relation !== undefined; // when a node is deleted, changes are relations are still here
      });

      setRelations({
        state: "loaded",
        content: relationsObject,
      });

      setStagedRelations(
        loadedRelations.map((relation) => {
          return relation.from === nodeId ? relation.to : relation.from;
        })
      );
    })();
  }, [nodeId]);
  if (
    !relations.content.length &&
    (globalUserInfo === 0 || globalUserInfo.role === "user")
  ) {
    return null;
  }
  const saveChanges = async () => {
    try {
      await directus_updateNodeRelations(nodeId, stagedRelations);

      setRelations({
        state: "loaded",
        content: stagedRelations.map((r) => {
          return all_people.filter((p) => {
            return p.id === r;
          })[0];
        }),
      });
      setEditMode(false);
      setReloadNodesTrigger(Date.now());
    } catch {
      setErrorMessage("Er ging iets mis...");
    }
  };
  const renderCheckBox = (relationnode) => {
    if (relationnode.id === nodeId) {
      return <></>;
    }
    return (
      <div key={`node-${relationnode.id}`} className="personSelect">
        <Form.Checkbox
          label={`${relationnode.first_name} ${relationnode.last_name}`}
          checked={stagedRelations.some((r) => r === relationnode.id)}
          onClick={(e, { checked }) => {
            if (stagedRelations === null) {
              const state = [relationnode.id];
              setStagedRelations(state);
            } else {
              let stateClone = stagedRelations.slice();
              if (checked) {
                setStagedRelations(stateClone.concat(relationnode.id));
              } else {
                setStagedRelations(
                  stateClone.filter((p) => relationnode.id !== p)
                );
              }
            }
            setUserDidEdit(true);
          }}
        />
      </div>
    );
  };
  let content = <Loader active />;
  if (relations.state !== "loading") {
    if (editMode) {
      const gebiedPeople = filteredPeople.filter(
        (p) => p.areatype === "gebied" && p.type === "person"
      );
      const ontwikkeltrajectPeople = filteredPeople.filter(
        (p) => p.areatype === "ontwikkeltraject"
      );
      const directiePeople = filteredPeople.filter(
        (p) => p.areatype === "directie"
      );
      const kklPeople = filteredPeople.filter((p) => p.areatype === "KKL");
      content = (
        <>
          {gebiedPeople.length ? (
            <>
              <h5>Experimenteergebieden</h5>
              <div className="columns list">
                {gebiedPeople.map(renderCheckBox)}
              </div>
            </>
          ) : null}
          {ontwikkeltrajectPeople.length ? (
            <>
              <h5>Ontwikkeltrajecten</h5>
              <div className="columns list">
                {ontwikkeltrajectPeople.map(renderCheckBox)}
              </div>
            </>
          ) : null}
          {kklPeople.length ? (
            <>
              <h5>KKL</h5>
              <div className="columns list">
                {kklPeople.map(renderCheckBox)}
              </div>
            </>
          ) : null}
          {directiePeople.length ? (
            <>
              <h5>Beleidsdirecties</h5>
              <div className="columns list">
                {directiePeople.map(renderCheckBox)}
              </div>
            </>
          ) : null}
        </>
      );
    } else {
      if (!relations.content.length) {
        content = <div className="emptyText">Nog geen relaties ingesteld.</div>;
      } else {
        const gebiedRelations = relations.content.filter(
          (p) => p.areatype === "gebied" && p.type === "person"
        );
        const ontwikkeltrajectRelations = relations.content.filter(
          (p) => p.areatype === "ontwikkeltraject" && p.type === "person"
        );
        const gremiumRelations = relations.content.filter(
          (p) => p.areatype === "ontwikkeltraject" && p.type === "gremium"
        );
        const directieRelations = relations.content.filter(
          (p) => p.areatype === "directie"
        );
        const kklRelations = relations.content.filter(
          (p) => p.areatype === "KKL"
        );
        content = (
          <div>
            {gremiumRelations.length ? (
              <>
                <h6>Relaties - Groepen</h6>
                <div className="columns list">
                  {gremiumRelations.map((relation) => {
                    return (
                      <NonPersonCard
                        key={`personcard-${relation.id}`}
                        node={relation}
                        asLink={true}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
            {ontwikkeltrajectRelations.length ? (
              <>
                <h6>Relaties - Ontwikkeltrajecten</h6>
                <div className="columns list">
                  {ontwikkeltrajectRelations.map((relation) => {
                    return (
                      <PersonCard
                        key={`personcard-${relation.id}`}
                        person={relation}
                        asLink={true}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
            {gebiedRelations.length ? (
              <>
                <h6>Relaties - Experimenteergebieden</h6>
                <div className="columns list">
                  {gebiedRelations.map((relation) => {
                    return (
                      <PersonCard
                        key={`personcard-${relation.id}`}
                        person={relation}
                        asLink={true}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}

            {kklRelations.length ? (
              <>
                <h6>Relaties - Team Experimenteergebieden KLL</h6>

                <div className="columns list">
                  {kklRelations.map((relation) => {
                    return (
                      <PersonCard
                        key={`personcard-${relation.id}`}
                        person={relation}
                        asLink={true}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
            {directieRelations.length ? (
              <>
                <h6>Relaties - Directies</h6>

                <div className="columns list">
                  {directieRelations.map((relation) => {
                    return (
                      <PersonCard
                        key={`personcard-${relation.id}`}
                        person={relation}
                        asLink={true}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
        );
      }
    }
  }

  return (
    <>
      <section className="divider"></section>

      <section className={`relations  ${editMode ? "editing" : null}`}>
        <div className="columns">
          {editMode ? (
            <Form.Input
              icon="search"
              placeholder={"Doorzoeken"}
              value={searchString}
              onChange={(item) => {
                setSearchString(item.target.value.toLowerCase());
              }}
            />
          ) : null}
        </div>
        {content}

        <EditBar
          editMode={editMode}
          setEditMode={setEditMode}
          saveChanges={saveChanges}
          errorMessage={errorMessage}
          userDidEdit={userDidEdit}
        />
      </section>
    </>
  );
};

export default ModalRelations;
