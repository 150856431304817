import { Suspense, useRef, useEffect, useState } from "react";
import useResizeObserver from "../hooks/useResizeObserver";
import FinalInfographic from "./finalInfographic";
import { useRecoilValue } from "recoil";
import { selector_filtered_areas } from "../recoil/selectors";
import {
  atom_zoomedExperimenteerGebied,
  atom_zoomedOntwikkeltraject,
  atom_relations,
} from "../recoil/atoms";
import { Loader } from "semantic-ui-react";
import {
  calculateDirectieCoordinates,
  calculateOntwikkelTrajectCoordinates,
  calculateKKLCoordinates,
  calculateExperimenteergebiedCoordinates,
} from "../helpers/d3/coordinate-functions";

const InfographicContainer = () => {
  const containerRef = useRef();
  const windowSize = useResizeObserver(containerRef);

  const zoomedGebiedAreas = useRecoilValue(atom_zoomedExperimenteerGebied);
  const zoomedOntwikkeltraject = useRecoilValue(atom_zoomedOntwikkeltraject);

  const allRelations = useRecoilValue(atom_relations);

  const [formattedAreas, setFormattedAreas] = useState({});
  const [formattedNodes, setFormattedNodes] = useState({});
  const [formattedRelations, setFormattedRelations] = useState({});
  const [dataReady, setDataReady] = useState(false);

  const [delayTimerFinished, setDelayTimerFinished] = useState(false);

  /* Data loading */
  const filteredAreas = useRecoilValue(selector_filtered_areas);

  /* Data Filtering and Formatting */
  const timeout = useRef(null);

  useEffect(() => {
    if (!windowSize || filteredAreas.length === 0 || allRelations.length === 0)
      return;

    // filter
    let areas = {
      directies: filteredAreas.filter((area) => {
        return area.type === "directie";
      }),

      ontwikkelTrajectAreas: filteredAreas.filter((area) => {
        if (zoomedOntwikkeltraject) {
          return (
            area.id === zoomedOntwikkeltraject &&
            area.type === "ontwikkeltraject"
          );
        }

        return area.type === "ontwikkeltraject";
      }),
      kklAreas: filteredAreas.filter((area) => {
        if (zoomedOntwikkeltraject) {
          return area.id === zoomedOntwikkeltraject && area.type === "KKL";
        }
        return area.type === "KKL";
      }),

      gebiedAreas: filteredAreas.filter((area) => {
        if (zoomedGebiedAreas) {
          return area.id === zoomedGebiedAreas && area.type === "gebied";
        }
        return area.type === "gebied";
      }),
    };

    //unset Badges on zoomed
    if (zoomedGebiedAreas) {
      areas.gebiedAreas = areas.gebiedAreas.map((area) => {
        return { ...area, badges: [] };
      });
    }
    // global settings
    const fontSize = (windowSize.height / 100) * 0.3 + 9; // keep in sync with CSS!!!
    const globalSettings = {
      marginTopAndBottomOntwikkelTrajectList: windowSize.height * 0.2,
      fontSize: fontSize,
      lineHeight: fontSize * 1.4,
      largeLineHeight: fontSize * 1.2 * 2,
      displayNodeCountLeftSide:
        windowSize.height > 730 ? 3 : windowSize.height > 520 ? 2 : 1,
      displayNodeCountRightSide: windowSize.height > 630 ? 3 : 2,
      XteamKLL:
        windowSize.width < 1100
          ? windowSize.width * 0.4
          : windowSize.width * 0.3,
    };

    let nodes = [];
    /* set coordinates for DIRECTRIES */
    areas.directies = calculateDirectieCoordinates(
      areas.directies[0], //there is only one area here
      windowSize,
      globalSettings
    );
    /* set coordinates and reduces nodes for ONTWIKKELTRAJECT AREAS */
    areas.ontwikkelTrajectAreas = calculateOntwikkelTrajectCoordinates(
      areas.ontwikkelTrajectAreas,
      windowSize,
      globalSettings,
      zoomedOntwikkeltraject ? false : true
    );
    // extract nodes
    for (let i = 0; i < areas.ontwikkelTrajectAreas.length; i++) {
      nodes = nodes.concat(areas.ontwikkelTrajectAreas[i].nodes);
      delete areas.ontwikkelTrajectAreas[i].nodes;
    }
    /* set coordinates and reduces nodes for KKL AREAS */
    areas.kklAreas = calculateKKLCoordinates(
      areas.kklAreas[0], //there is only one area here
      windowSize,
      globalSettings,
      zoomedOntwikkeltraject ? false : true
    );
    // extract nodes
    if (areas.kklAreas.length) {
      nodes = nodes.concat(areas.kklAreas[0].nodes);
      delete areas.kklAreas[0].nodes;
    }

    /* Remove KKL and Ontwikkeltrajecten if zoomed */
    /* We did run the functions to get node cooridinates */
    if (zoomedOntwikkeltraject) {
      areas.ontwikkelTrajectAreas = [];
      areas.kklAreas = [];
    }

    /* set coordinates and reduces nodes for experiementeergebiedAreas Areas */
    areas.gebiedAreas = calculateExperimenteergebiedCoordinates(
      areas.gebiedAreas,
      windowSize,
      zoomedGebiedAreas ? false : true,
      globalSettings,
      filteredAreas // for badges
    );

    // extract nodes
    for (let i = 0; i < areas.gebiedAreas.length; i++) {
      nodes = nodes.concat(areas.gebiedAreas[i].nodes);
      delete areas.gebiedAreas[i].nodes;
    }

    const leftNodes = nodes.filter((n) => n.areaType === "ontwikkeltraject");
    const rightNodes = nodes.filter((n) => n.areaType !== "ontwikkeltraject");

    /* filter what relations should be visible */
    let relations = allRelations.filter((r) => {
      const fromIsVisibleNode = nodes.some((n) => r.from === n.id);
      const toIsVisibleNode = nodes.some((n) => r.to === n.id);

      const fromIsVisibleDirectieNode = areas.directies[0].nodes.some(
        (n) => r.from === n.id
      );
      const toIsVisibleDirectieNode = areas.directies[0].nodes.some(
        (n) => r.to === n.id
      );

      if (
        (fromIsVisibleNode || fromIsVisibleDirectieNode) &&
        (toIsVisibleNode || toIsVisibleDirectieNode)
      ) {
        return true;
      }

      return false;
    });
    relations = relations.map((relation, index) => {
      let { position: sourcePostion } =
        nodes.find((node) => node.id === relation.from) ||
        areas.directies[0].nodes.find((node) => node.id === relation.from);
      let { position: targetPosition } =
        nodes.find((node) => node.id === relation.to) ||
        areas.directies[0].nodes.find((node) => node.id === relation.to);

      return {
        ...relation,
        source: sourcePostion,
        target: targetPosition,
      };
    });

    setFormattedAreas(areas);
    setFormattedNodes({ leftNodes, rightNodes });
    setFormattedRelations(relations);
    setDataReady(true);
    if (timeout.current === null) {
      timeout.current = setTimeout(() => {
        setDelayTimerFinished(true);
      }, 750);
    }
  }, [
    filteredAreas,
    zoomedGebiedAreas,
    zoomedOntwikkeltraject,
    allRelations,
    windowSize,
  ]);

  return (
    <div className="infographicContainer" ref={containerRef}>
      {dataReady ? (
        <FinalInfographic
          formattedAreas={formattedAreas}
          formattedNodes={formattedNodes}
          formattedRelations={formattedRelations}
          zoomedGebiedAreas={zoomedGebiedAreas}
          windowSize={windowSize}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default InfographicContainer;

export const EmptyInfographicContainer = () => (
  <div className="infographicContainer">
    <Loader />
  </div>
);
