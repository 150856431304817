// EXAMPLE: https://codesandbox.io/s/s55j7?file=/src/index.js:414-486

import { selector_timelineBoundaries } from "../recoil/selectors";
import {
  atom_selected_timeline_date,
  atom_modal_state,
  atom_currently_selected_node,
} from "../recoil/atoms";
import useResizeObserver from "../hooks/useResizeObserver";

import { useRecoilValue, useRecoilState } from "recoil";
import { useMotionValue, motion, transform } from "framer-motion";
import { useRef, useEffect, useState } from "react";
import { scaleLinear } from "d3";
import { formatTimestamp } from "../helpers/dates-functions";
import icon_timeline_dragger from "../img/icon_timeline_dragger.svg";

const Timeline = () => {
  const modalState = useRecoilValue(atom_currently_selected_node);

  const timelineContraintRef = useRef();
  const timelineBoundaries = useRecoilValue(selector_timelineBoundaries);
  const [selectedTimelineDate, setSelectedTimelineDate] = useRecoilState(
    atom_selected_timeline_date
  );
  const [timelineWidth, setTimelineWidth] = useState(0);
  const sliderXVal = useMotionValue(0);
  const [hideDate, setHideDate] = useState(false);

  const timelineSize = useResizeObserver(timelineContraintRef);

  const setHideTimer = () => {
    setTimeout(() => {
      setHideDate(true);
    }, 1000);
  };
  useEffect(() => {
    const max = timelineContraintRef.current.clientWidth;
    setTimelineWidth(max);

    const scale = scaleLinear()
      .domain([0, max])
      .range([timelineBoundaries.min, timelineBoundaries.max]); //  TODO dit nog dynamisch maken maar zorgt nu voor re-render

    sliderXVal.onChange(() => {
      setSelectedTimelineDate(scale(sliderXVal.get()));
    });
    setTimeout(() => {
      sliderXVal.set(scale.invert(selectedTimelineDate));
    }, 1000);
  }, [timelineBoundaries, timelineSize]);

  return (
    <motion.div
      className="timeline"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.25 } }}
    >
      <div className="label">Tijdlijn</div>
      <div className="timeline-drag-constraint" ref={timelineContraintRef}>
        <motion.div
          className="dragger"
          style={{ x: sliderXVal }}
          drag="x"
          dragMomentum={false}
          dragConstraints={{
            left: 0,
            right: timelineWidth,
          }}
          dragElastic={0.1}
          onDragStart={() => setHideDate(false)}
        >
          <img src={icon_timeline_dragger} alt="" />
          {!hideDate ? <TimeSpan timestamp={selectedTimelineDate} /> : null}
        </motion.div>
      </div>
    </motion.div>
  );
};
export default Timeline;

const TimeSpan = ({ timestamp }) => {
  return (
    <div
      className="editFormElement ui top center popup transition visible"
      style={{
        pointerEvents: "none",
        width: "100px",
        textAlign: "center",
        // display: "block",
        // position: "absolute",
        top: "-4em",
        // transformOrigin: "left center",
        transform: "translateX(35%)",
      }}
    >{`${formatTimestamp(timestamp)}`}</div>
  );
};
