import { useState, useEffect } from "react";
import {
  formatTimestamp,
  logBookFormatTimestamp,
} from "../../../helpers/dates-functions";
import { nl2br } from "../../../helpers/string-functions";
import { atom_userInfo } from "../../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { Form, Icon, Button, Loader } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {
  directus_save_logbookItem,
  directus_delete_logbookItem,
  directus_getLogbookItemsForArea,
} from "../../../services/directus/helpers";

const ModalLogBook = ({ node = null, ontwikkeltraject = null }) => {
  const initiaItem = {
    id: null,
    timestamp: Date.now(),
    text: "",
    node: node !== null ? node.id : null,
    area: ontwikkeltraject !== null ? ontwikkeltraject.id : null,
  };
  const globalUserInfo = useRecoilValue(atom_userInfo);
  const [logbookItems, setLogBookItems] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editedItem, setEditedItem] = useState(initiaItem);
  const [editItemId, setEditedItemID] = useState(null);
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const sortItems = (items) => {
    if (items === null || items.length < 1) {
      return [];
    }
    return items.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  };

  const clickEditItem = (id) => {
    setEditedItemID(id);
    setEditMode(false);
    const selected = logbookItems.find((i) => i.id === id);
    setEditedItem(logbookItems.find((i) => i.id === id));
  };
  const confirmRemoval = async (id) => {
    if (await directus_delete_logbookItem(id)) {
      setLogBookItems(logbookItems.filter((i) => i.id != id));

      setRemoveConfirm(null);
    }
  };
  const clickRemoveItem = (id) => {
    if (removeConfirm !== id) {
      setRemoveConfirm(id);
      return;
    }
  };

  const loadLogbookForArea = async () => {
    const response = await directus_getLogbookItemsForArea(ontwikkeltraject.id);
    if (response) {
      setLogBookItems(sortItems(response));
    }
  };
  useEffect(() => {
    if (node !== null) {
      setLogBookItems(sortItems(node.logbookitems));
    } else {
      loadLogbookForArea(ontwikkeltraject);
    }
  }, []);
  if (node != null && node.type !== "experiment") {
    return null;
  }
  const saveItem = async () => {
    const isNew = editedItem.id === null;

    const updateObject = (({ timestamp, id, area, text }) => ({
      timestamp,
      id,
      area,
      text,
    }))(editedItem);

    setErrorMessage(false);
    const response = await directus_save_logbookItem(updateObject);

    if (response) {
      if (isNew) {
        const newArray = [response].concat(logbookItems);
        setLogBookItems(sortItems(newArray));
      } else {
        const newArray = logbookItems.map((i) => {
          return i.id === editedItem.id ? response : i;
        });
        setLogBookItems(sortItems(newArray));
      }
      setEditedItemID(null);
      setEditMode(false);
    } else {
      setErrorMessage("Er ging iets mis...");
    }
  };
  const onInputChange = (e) => {
    setEditedItem({ ...editedItem, text: e.target.value });
  };

  const renderEditButtons = (id) => {
    if (globalUserInfo === 0 || globalUserInfo.role === "user") return null;
    if (removeConfirm === id) {
      return null;
    }
    return (
      <div className="edit">
        <Icon
          size="small"
          name="edit"
          onClick={() => {
            clickEditItem(id);
          }}
        />
        <Icon
          onClick={() => {
            clickRemoveItem(id);
          }}
          size="small"
          name="trash alternate outline"
          color="red"
        />
      </div>
    );
  };
  const renderAdminInputRow = (id) => {
    if (globalUserInfo === 0 || globalUserInfo.role === "user") return null;
    if (!editMode && undefined === id) {
      return (
        <tr>
          <td></td>
          <td></td>
          <td
            style={{ padding: "2em 0", cursor: "pointer" }}
            onClick={() => {
              setEditMode(true);
              setErrorMessage(false);
              setEditedItem(initiaItem);
              setEditedItemID(null);
            }}
          >
            <Icon circular name="plus" />
            Voeg logboek item toe
          </td>
        </tr>
      );
    }
    return (
      <tr key="editRow">
        <td class="date">
          <DatePicker
            selected={editedItem.date}
            onChange={(date) =>
              setEditedItem({
                ...editedItem,
                timestamp: Date.parse(date),
              })
            }
            customInput={
              <strong>{logBookFormatTimestamp(editedItem.timestamp)}</strong>
            }
          />
        </td>
        <td>
          <div className="timelineSpot"></div>
        </td>
        <td>
          <div>
            <Form>
              <Form.TextArea value={editedItem.text} onChange={onInputChange} />
              <div className="editBar dontHide alignLeft">
                {errorMessage != null ? <div>{errorMessage}</div> : null}

                <Form.Button
                  onClick={() => {
                    saveItem();
                  }}
                >
                  {editMode ? "Opslaan" : "Aanpassen"}
                </Form.Button>
                <Form.Button
                  onClick={() => {
                    setEditMode(false);
                    setEditedItemID(null);
                  }}
                >
                  Annuleren
                </Form.Button>
              </div>
            </Form>
          </div>
        </td>
      </tr>
    );
  };

  let content = <></>;
  if (
    (logbookItems && logbookItems.length) ||
    (globalUserInfo !== 0 && globalUserInfo.role !== "user")
  ) {
    content = (
      <div
        className={`logbookContainer ${
          globalUserInfo !== 0 && globalUserInfo.role !== "user"
            ? "editmode"
            : null
        }`}
      >
        <div className="timeline-line"></div>

        <table className="logbook">
          <tbody>
            {renderAdminInputRow()}

            {logbookItems.map((item) => {
              if (item.id === editItemId) {
                return renderAdminInputRow(item.id);
              }
              return (
                <tr key={`logbookitem-${item.id}`}>
                  <td className="date">
                    <strong>{logBookFormatTimestamp(item.timestamp)}</strong>
                  </td>
                  <td>
                    <div className="timelineSpot"></div>
                  </td>
                  {removeConfirm === item.id ? (
                    <td>
                      <div style={{ color: "red " }}>
                        Weet je zeker dat je dit wilt verwijderen?
                      </div>
                      <div>
                        <div className="button">
                          <Button
                            onClick={() => {
                              confirmRemoval(item.id);
                            }}
                          >
                            Ja, verwijderen
                          </Button>
                          <Button
                            onClick={() => {
                              setRemoveConfirm(false);
                            }}
                          >
                            Annuleren
                          </Button>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: nl2br(item.text),
                        }}
                      />
                      {renderEditButtons(item.id)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
  return (
    <>
      <section className="divider"></section>
      <section>{content}</section>
    </>
  );
};
export default ModalLogBook;
