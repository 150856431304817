import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import "./App.scss";
import IntroScreen from "./components/introScreen";
import ProtectedApp from "./components/protectedApp";
import { useRecoilState } from "recoil";
import { atom_userInfo } from "./recoil/atoms";
import {
  directus_getUserInfo,
  directus_refreshAuth,
} from "./services/directus/helpers";
import { Loader, Icon, Button } from "semantic-ui-react";

function App() {
  const [globalUserInfo, setGlobalUserInfo] = useRecoilState(atom_userInfo);
  const [loggedInCheckFinished, setLoggedInCheckFinished] = useState(false);
  const [displaySmallScreenMessage, setDisplaySmallScreenMessage] = useState(
    true
  );
  useEffect(() => {
    (async () => {
      const response = await directus_refreshAuth();

      if (response) {
        directus_getUserInfo()
          .then((response) => {
            setGlobalUserInfo(response);
            setLoggedInCheckFinished(true);
          })
          .catch(() => {});
      } else {
        setLoggedInCheckFinished(true);
      }
    })();
  }, []);
  if (!loggedInCheckFinished) {
    return (
      <div className="app">
        <Loader active />
      </div>
    );
  }
  return (
    <div className="app">
      {globalUserInfo === 0 ? (
        <IntroScreen loggedInCheckFinished={loggedInCheckFinished} />
      ) : (
        <ProtectedApp />
      )}
      {displaySmallScreenMessage ? (
        <div
          className="smallScreenMessage"
          onClick={() => {
            setDisplaySmallScreenMessage(false);
          }}
        >
          <div>
            <h1>
              <Icon name="tv" />
              Schermgrootte
            </h1>
            <p>
              Dit scherm is te klein om de Kringlooplandbouw Trajectkaart weer
              te geven.
              <br />
              Maak indien mogelijk je scherm groter, of bekijk de kaart op een
              ander apparaat.
            </p>
            <Button inverted>Bericht sluiten</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
