/* OM OVERZICHT TE BEWAREN:
  - app.js -> app structuur
  - infograficContainer -> data laden + data formateren + schalen (coordinaten berekenen)
  - finalInfographic -> d3 drawing + transitions 
*/
import React, { Suspense, useState, useEffect, useCallback } from "react";

import InfographicContainer, {
  EmptyInfographicContainer,
} from "./infographicContainer";
import Header from "./header";
import Timeline from "./timeline";
import UserWindows from "./userwindows";
import Modal from "./modal/modal";
import UserDashboard from "./userDashboard";
import {
  atom_areas,
  atom_themes,
  atom_modal_state,
  atom_reloadNodesTrigger,
  atom_showdashboardScreen,
  atom_relations,
  atom_userInfo,
} from "../recoil/atoms";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import {
  directus_getAreas,
  directus_getThemes,
  directus_getRelations,
  directus_getUserInfo,
} from "../services/directus/helpers";
import { Loader } from "semantic-ui-react";

function ProtectedApp() {
  const setModalState = useSetRecoilState(atom_modal_state);
  const setShowDashboardScreen = useSetRecoilState(atom_showdashboardScreen);
  const [areas, setAreas] = useRecoilState(atom_areas);
  const setRelations = useSetRecoilState(atom_relations);
  const setThemes = useSetRecoilState(atom_themes);
  const reloadNodes = useRecoilValue(atom_reloadNodesTrigger);

  const escFunction = useCallback(({ keyCode }) => {
    if (keyCode === 27) {
      //escape
      setModalState({ mode: "hidden" });
      setShowDashboardScreen(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const loadedAreas = await directus_getAreas();
      setAreas(loadedAreas);
      const loadedThemes = await directus_getThemes();
      setThemes(loadedThemes);
      const loadedRelations = await directus_getRelations();
      setRelations(loadedRelations);
    })();
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [reloadNodes]);

  if (!areas) {
    return (
      <div className="initLoading">
        <Loader active />
      </div>
    );
  }
  return (
    <div className="app">
      <Header />
      <main>
        <Suspense fallback={<EmptyInfographicContainer />}>
          <InfographicContainer />
        </Suspense>

        <UserWindows />
      </main>
      <Suspense fallback="loading">
        <Timeline />
      </Suspense>

      <Modal />
      <UserDashboard />
    </div>
  );
}

export default ProtectedApp;
