import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  atom_showdashboardScreen,
  atom_userInfo,
  atom_anonymizeNames,
  atom_reloadNodesTrigger,
} from "../recoil/atoms";
import {
  useRecoilState,
  useRecoilStateLoadable,
  useSetRecoilState,
} from "recoil";
import { Form, Button, Icon, Header, Message } from "semantic-ui-react";
import { useState, useEffect } from "react";

import {
  directus_getUserInfo,
  directus_loginUser,
  directus_logoutUser,
} from "../services/directus/helpers";
import directus from "../services/directus/directus";

const UserDashboard = () => {
  const [showdashboardScreen, setShowdashboardScreen] = useRecoilState(
    atom_showdashboardScreen
  );
  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);

  const [globalUserInfo, setGlobalUserInfo] = useRecoilStateLoadable(
    atom_userInfo
  );
  const [anonymizeNames, setAnonymizeNames] = useRecoilState(
    atom_anonymizeNames
  );
  const [showPasswordChangeView, setShowPasswordCHangeView] = useState(false);
  const [error, setError] = useState(false);
  const [succesMessage, setSuccesMessage] = useState(false);
  const [formdata, setFormData] = useState({
    password1: "",
    password2: "",
  });

  useEffect(() => {
    setShowPasswordCHangeView(false);
  }, [showdashboardScreen]);

  const onChangeFormElement = (e, { name, value }) => {
    setFormData({ ...formdata, [name]: value });
  };

  const handleSubmit = async () => {
    if (formdata.password1 != formdata.password2) {
      setError("Ingevulde passwords komen niet overeen.");
      return;
    }
    if (formdata.password1.length < 6) {
      setError("Aub een langer password kiezen.");
      return;
    }

    try {
      await directus.users.me.update({
        password: formdata.password1,
      });
      setError(false);
      setSuccesMessage("Je nieuwe wachtwoord is opgeslagen");
      setFormData({
        password1: "",
        password2: "",
      });
      setShowPasswordCHangeView(false);
    } catch {
      setError("Opslaan van nieuw wachtwoord is niet gelukt.");
    }
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {showdashboardScreen ? (
        <motion.div
          key={`dashboardScreen_dimmer`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          exit={{
            opacity: 0,
            transition: { duration: 0.2 },
          }}
          className="dashboardScreen_dimmer"
          onClick={() => {
            setShowdashboardScreen(false);
            setFormData({ password1: "", password2: "" });
            setError(false);
          }}
        >
          <div
            className="dashboardScreen"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Header icon>
              <Icon name="user" />

              {globalUserInfo.contents.first_name
                ? globalUserInfo.contents.first_name + " "
                : ""}
              {globalUserInfo.contents.last_name
                ? globalUserInfo.contents.last_name
                : ""}
            </Header>
            <div>
              {error && <Message color="yellow">{error}</Message>}
              {succesMessage && (
                <Message color="green">{succesMessage}</Message>
              )}
              {showPasswordChangeView ? (
                <>
                  <Form onSubmit={handleSubmit}>
                    <label>
                      {"Nieuw password"}
                      <Form.Input
                        type="password"
                        name="password1"
                        value={formdata.password1}
                        autocomplete="new-password"
                        onChange={onChangeFormElement}
                      />
                    </label>
                    <label>
                      {"Typ nieuw password nogmaals"}
                      <Form.Input
                        type="password"
                        name="password2"
                        value={formdata.password2}
                        autocomplete="new-password"
                        onChange={onChangeFormElement}
                      />
                    </label>
                    <br />
                    <Form.Group>
                      <Form.Button type="submit">{"Aanpassen"}</Form.Button>
                      <Form.Button
                        onClick={() => {
                          setShowPasswordCHangeView(false);
                          setFormData({ password1: "", password2: "" });
                          setError(false);
                        }}
                      >
                        {"Annuleren"}
                      </Form.Button>
                    </Form.Group>
                  </Form>
                </>
              ) : (
                <>
                  <ul>
                    <li
                      onClick={() => {
                        setAnonymizeNames(!anonymizeNames);
                        setReloadNodesTrigger(Date.now());
                      }}
                    >
                      <Icon name="arrow alternate circle right outline" />
                      {anonymizeNames
                        ? "Namen weergeven"
                        : "Namen anonimiseren"}
                    </li>
                    <li
                      onClick={() => {
                        setShowPasswordCHangeView(true);
                        setSuccesMessage(false);
                        setError(false);
                      }}
                    >
                      <Icon name="arrow alternate circle right outline" />
                      Wachtwoord aanpassen
                    </li>
                    <li
                      onClick={async () => {
                        try {
                          await directus_logoutUser();
                          setShowdashboardScreen(false);
                          setGlobalUserInfo(0);
                        } catch {}
                      }}
                    >
                      <Icon name="arrow alternate circle right outline" />{" "}
                      Uitloggen
                    </li>
                  </ul>
                </>
              )}
              <div
                className="close"
                onClick={() => {
                  setShowdashboardScreen(false);
                  setFormData({ password1: "", password2: "" });
                  setError(false);
                }}
              >
                <Icon name="remove" />
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>,
    document.getElementById("modalContainer")
  );
};
export default UserDashboard;
