import { scaleLinear, sort } from "d3";
import { stripToSaveFromName } from "../string-functions";

const calculateNodesInZoomedExperimenteerGebied = (
  index,
  scale,
  windowSize,
  isExperiment
) => {
  const x = isExperiment
    ? (windowSize.width / 3) * 2 + 50
    : windowSize.width / 2 + 50;
  return { x, y: scale(index) }; // 50 = padding used in css .windowContent
};
const calculateNodesAroundExperimenteerdgebiedCircle = (
  index,
  amountOfPoints,
  circlePosition,
  globalSettings
) => {
  const lineheight = globalSettings.lineHeight || 17;
  const extraMarginAroundCircle = 2;
  const radialsForOneSegment = Math.asin(
    lineheight / (circlePosition.r + extraMarginAroundCircle)
  );
  const indexOfMiddleInList = (amountOfPoints - 1) / 2;
  const amountOfsegmentsForThisPoint = (indexOfMiddleInList - index) * -1;
  const x =
    Math.cos(radialsForOneSegment * amountOfsegmentsForThisPoint) *
      (circlePosition.r + extraMarginAroundCircle) +
    circlePosition.x;
  const y =
    Math.sin(radialsForOneSegment * amountOfsegmentsForThisPoint) *
      (circlePosition.r + extraMarginAroundCircle) +
    circlePosition.y;

  return { x, y };
};

const calculateBadgePositionZoomed = (index, windowSize) => {
  return {
    x: windowSize.width - 100,
    y: 100 + 20 * index,
  };
};
const calculateBadgePositionAroundCircle = (
  index,
  amountOfPoints,
  circlePosition
) => {
  const radials = Math.PI / -2 - 0.4 * index - 0.6;
  /*
    Math.pi/-2 -> top
    - 0.6 -> start offset
    - 0.4 * index -> offset because of index
  */

  const x = Math.cos(radials) * (circlePosition.r + 20) + circlePosition.x;
  const y = Math.sin(radials) * (circlePosition.r + 20) + circlePosition.y;
  return {
    x: x,
    y: y,
  };
};
export const calculateExperimenteergebiedCoordinates = (
  areas,
  windowSize,
  summarizeNodes,
  globalSettings,
  ontwikkeltrajecten
) => {
  return areas.map((area) => {
    // LET OP, DEZE WAARDEN STAAN OP 3 PLEKKEN
    const nlMapWidth = 568,
      nlMapHeight = 673; //it's a given in the SVG
    const margin = windowSize.height * 0.15,
      marginRight = windowSize.width * 0.1;
    const ratio = (windowSize.height - margin) / nlMapHeight;
    const renderedNLMapWidthSize = nlMapWidth * ratio;

    const scaleX = scaleLinear()
      .domain([0, 100])
      .range([
        windowSize.width - renderedNLMapWidthSize - marginRight,
        windowSize.width - marginRight,
      ]);
    const scaleY = scaleLinear()
      .domain([0, 100])
      .range([margin / 2, windowSize.height - margin / 2]);

    const areaPosition = {
      x: scaleX(area.position_x_percentage),
      y: scaleY(area.position_y_percentage),
      r: windowSize.width / 50,
    };

    let nodes = area.nodes;
    if (nodes === null || nodes === undefined) {
      nodes = [];
    }

    const unSummarizedNodeCount = nodes ? nodes.length : 0;
    // filter out experiments
    let experimentNodes = nodes.filter((node) => {
      return node.type === "experiment";
    });
    nodes = nodes.filter((node) => {
      return node.type !== "experiment";
    });
    nodes = sortNodesBasedOnPriority(nodes);
    if (summarizeNodes) {
      const experimentCount = experimentNodes.length;
      if (experimentCount === 1) {
        nodes = [experimentNodes[0]].concat(nodes);
      } else if (experimentCount > 1) {
        nodes = [
          {
            id: `${experimentCount}-experiments-in-${area.title}`,
            type: "experiment",
            areaId: area.id,
            first_name: "",
            last_name: `${experimentCount} experimenten`,
          },
        ].concat(nodes);
      }
      nodes = nodes.slice(0, globalSettings.displayNodeCountRightSide);
      if (unSummarizedNodeCount > globalSettings.displayNodeCountRightSide) {
        const personCount = unSummarizedNodeCount - experimentCount;
        const visiblePersonCount = experimentCount
          ? nodes.length - 1
          : nodes.length;

        const hiddenPersonCount = personCount - visiblePersonCount;

        nodes.push({
          id: `${
            area.nodes.length - globalSettings.displayNodeCountRightSide
          }-more-in-${area.title}`,
          areaId: area.id,
          type: "info",
          first_name: `+ ${hiddenPersonCount} ${
            hiddenPersonCount === 1 ? "persoon" : "personen"
          }`,
          last_name: "",
        });
      }
      nodes = nodes.map((node, index) => {
        return {
          ...node,
          areaType: "experimenteergebied",
          position: calculateNodesAroundExperimenteerdgebiedCircle(
            index,
            nodes.length,
            areaPosition,
            globalSettings
          ),
        };
      });
    } else {
      const personScale = scaleLinear()
        .domain([0, nodes.length - 1])
        .range([
          windowSize.height / 2 -
            (nodes.length / 2) * globalSettings.largeLineHeight,
          windowSize.height / 2 +
            (nodes.length / 2) * globalSettings.largeLineHeight,
        ]);
      nodes = nodes.map((node, index) => {
        return {
          ...node,
          areaType: "experimenteergebied",
          position: calculateNodesInZoomedExperimenteerGebied(
            index,
            personScale,
            windowSize
          ),
        };
      });
      const experimentScale = scaleLinear()
        .domain([0, experimentNodes.length - 1])
        .range([
          windowSize.height / 2 -
            (experimentNodes.length / 2) * globalSettings.largeLineHeight,
          windowSize.height / 2 +
            (experimentNodes.length / 2) * globalSettings.largeLineHeight,
        ]);

      experimentNodes = experimentNodes.map((node, index) => {
        return {
          ...node,
          areaType: "experimenteergebied",
          position: calculateNodesInZoomedExperimenteerGebied(
            index,
            experimentScale,
            windowSize,
            true
          ),
        };
      });
      nodes = nodes.concat(experimentNodes);
    }

    let badges = area.badges === null ? [] : area.badges;
    badges = badges.filter((badge) => badge.visible);
    badges = badges.map((badge, index) => {
      const areaObject = ontwikkeltrajecten.filter((a) => {
        return a.id === badge.areas_related_id;
      });
      if (areaObject.length) {
        return {
          id: badge.id,
          title: stripToSaveFromName(areaObject[0].title),
          formattedTitle: areaObject[0].title,
          position: summarizeNodes
            ? calculateBadgePositionAroundCircle(
                index,
                badges.length,
                areaPosition
              )
            : calculateBadgePositionAroundCircle(index, badges.length, {
                x: (windowSize.width / 5) * 4,
                y: windowSize.height / 2,
                r: 90,
              }),
        };
      }
      return { title: "unknown" };
    });

    return {
      ...area,
      position: areaPosition,
      nodes,
      badges,
    };
  });
};

export const calculateDirectieCoordinates = (
  area,
  windowSize,
  globalSettings
) => {
  const centerline = windowSize.height / 2;
  const lineheight = 60; // TODO deze straks misschien afhankelijk maken van fontgrootte
  const itemCount = area.nodes.length;
  const scale = scaleLinear()
    .domain([0, itemCount - 1])
    .range([
      centerline - (itemCount / 2) * lineheight,
      centerline + (itemCount / 2) * lineheight,
    ]);

  return [
    {
      ...area,
      areaType: "directie",

      nodes: area.nodes.map((node, index) => {
        return {
          ...node,
          areaType: "directie",
          position: {
            x: 70,
            y: scale(index),
          },
        };
      }),
    },
  ];
};
export const calculateKKLCoordinates = (
  area,
  windowSize,
  globalSettings,
  summarizeNodes
) => {
  if (undefined === area) {
    return [];
  }
  const centerline = windowSize.height / 2;
  const lineheight = summarizeNodes ? 16 : 30; // TODO deze straks misschien afhankelijk maken van fontgrootte

  let itemCount = 0;
  if (undefined !== area.nodes && area.nodes !== null) {
    itemCount =
      area.nodes.length > globalSettings.displayNodeCountLeftSide
        ? globalSettings.displayNodeCountLeftSide + 1 // +1 because of areatitle
        : area.nodes.length + 1; //+1 because of areatitle
  }

  const scale = scaleLinear()
    .domain([0, itemCount])
    .range([
      centerline - (itemCount / 2) * lineheight,
      centerline + (itemCount / 2) * lineheight,
    ]);
  let nodes = [];
  let gremiaNodes = [];
  if (area.nodes !== undefined && area.nodes !== null) {
    const peopleNodes = area.nodes.filter((node) => node.type === "person");
    gremiaNodes = area.nodes.filter((node) => node.type === "gremium");

    let sortedNodes = sortNodesBasedOnPriority(peopleNodes);
    if (
      summarizeNodes &&
      sortedNodes.length > globalSettings.displayNodeCountLeftSide
    ) {
      const hiddenPersonCount =
        sortedNodes.length - globalSettings.displayNodeCountLeftSide;
      sortedNodes = sortedNodes.slice(
        0,
        globalSettings.displayNodeCountLeftSide
      );

      sortedNodes.push({
        id: `${
          area.nodes.length - globalSettings.displayNodeCountLeftSide
        }-more-in-${area.title}`,
        areaId: area.id,
        type: "info",
        first_name: `+ ${hiddenPersonCount} ${
          hiddenPersonCount === 1 ? "persoon" : "personen"
        }`,
        last_name: "",
      });
      nodes = sortedNodes;
    } else {
      nodes = sortedNodes;
    }
  }

  nodes = nodes.map((node, index) => {
    return {
      ...node,
      areaType: "ontwikkeltraject",
      position: {
        x: summarizeNodes ? globalSettings.XteamKLL : 170,
        y: scale(summarizeNodes ? index + 1 : index), // area title = index 0
      },
    };
  });

  const gremiaNodesScale = scaleLinear()
    .domain([0, gremiaNodes.length > 0 ? gremiaNodes.length : 0])
    .range([
      centerline - (gremiaNodes.length / 2) * globalSettings.largeLineHeight,
      centerline + (gremiaNodes.length / 2) * globalSettings.largeLineHeight,
    ]);

  gremiaNodes = gremiaNodes.map((node, nodeIndex) => {
    return {
      ...node,
      areaType: "ontwikkeltraject",
      position: {
        x: globalSettings.XteamKLL,
        y: gremiaNodesScale(nodeIndex),
      },
    };
  });

  return [
    {
      ...area,
      position: {
        x: globalSettings.XteamKLL,
        y: scale(0),
      },

      nodes: summarizeNodes ? nodes : nodes.concat(gremiaNodes),
    },
  ];
};

const personRolesPriorities = [
  "gebiedsmanager",
  "programmamanager",
  "manager",
  "projectleider",
  "dossierhouder",
  "beleidsmedewerker",
  "projectmedewerker",
  "medewerker",
  "ondernemer",
  "communicatiemedewerker",
  "lagereoverheid", // wordt uitgefaseerd
  "lokaleoverheid",
  "expertonderzoeker", // wordt uitgefaseerd
  "expert",
  "onderzoeker",
];

const sortNodesBasedOnPriority = (nodes) => {
  if (nodes.length < 2) {
    return nodes;
  }

  const sortedNodes = nodes.slice().sort((a, b) => {
    if (a.type === "experiment" && b.type === "experiment") {
      return a.last_name - b.last_name; //alpabethic
    }
    if (a.type === "experiment" && b.type !== "experiment") {
      return -1;
    }
    if (a.type !== "experiment" && b.type === "experiment") {
      return 1;
    }

    if (personRolesPriorities.indexOf(a.role) === -1) {
      console.log(`missing role for id ${a.id}: ${a.role}`);
    }
    if (personRolesPriorities.indexOf(b.role) === -1) {
      console.log(`missing role for id ${b.id}: ${b.role}`);
    }

    return (
      personRolesPriorities.indexOf(a.role) -
      personRolesPriorities.indexOf(b.role)
    );
  });

  // set Primary Contact to be beginnen of array
  const contactPersonIndex = sortedNodes.findIndex((n) => n.is_contactperson);
  if (undefined !== contactPersonIndex && contactPersonIndex !== 0) {
    sortedNodes.unshift(sortedNodes.splice(contactPersonIndex, 1)[0]);
  }

  return sortedNodes;
};

export const calculateOntwikkelTrajectCoordinates = (
  areas,
  windowSize,
  globalSettings,
  summarizeNodes
) => {
  const ontwikkeltrajectScale = scaleLinear()
    .domain([0, areas.length - 1])
    .range([
      globalSettings.marginTopAndBottomOntwikkelTrajectList,
      windowSize.height - globalSettings.marginTopAndBottomOntwikkelTrajectList,
    ]);

  if (!areas.length) {
    areas = [];
  }
  areas = areas.map((area, index) => {
    const areaY = summarizeNodes
        ? ontwikkeltrajectScale(index)
        : globalSettings.marginTopAndBottomOntwikkelTrajectList,
      areaX = 170;

    let nodes = [];
    let gremiaNodes = [];
    if (area.nodes !== undefined && area.nodes !== null) {
      const peopleNodes = area.nodes.filter((node) => node.type === "person");
      gremiaNodes = area.nodes.filter((node) => node.type === "gremium");
      let sortedNodes = sortNodesBasedOnPriority(peopleNodes);
      if (
        summarizeNodes &&
        sortedNodes.length > globalSettings.displayNodeCountLeftSide
      ) {
        const hiddenPersonCount =
          sortedNodes.length - globalSettings.displayNodeCountLeftSide;
        sortedNodes = sortedNodes.slice(
          0,
          globalSettings.displayNodeCountLeftSide
        );

        sortedNodes.push({
          id: `${
            area.nodes.length - globalSettings.displayNodeCountLeftSide
          }-more-in-${area.title}`,
          areaId: area.id,
          type: "info",
          first_name: `+ ${hiddenPersonCount} ${
            hiddenPersonCount === 1 ? "persoon" : "personen"
          }`,
          last_name: "",
        });
        nodes = sortedNodes;
      } else {
        nodes = sortedNodes;
      }
    }

    const centerline = windowSize.height / 2;

    const itemCount = nodes.length;
    const nodeDomain = summarizeNodes
      ? [0, globalSettings.displayNodeCountLeftSide]
      : [0, itemCount - 1];

    const nodeRange = summarizeNodes
      ? [
          areaY + globalSettings.lineHeight,
          areaY +
            +globalSettings.lineHeight +
            globalSettings.displayNodeCountLeftSide * globalSettings.lineHeight,
        ] // TODO 20/40 straks misschien afhankelijk maken van fontgrootte (en die van windowheight?)
      : [
          centerline - (itemCount / 2) * globalSettings.largeLineHeight,
          centerline + (itemCount / 2) * globalSettings.largeLineHeight,
        ];
    const nodesScale = scaleLinear().domain(nodeDomain).range(nodeRange);

    nodes = nodes.map((node, nodeIndex) => {
      return {
        ...node,
        areaType: "ontwikkeltraject",
        position: {
          x: areaX - 10,
          y: nodesScale(nodeIndex),
        },
      };
    });

    const gremiaNodesScale = scaleLinear()
      .domain([0, gremiaNodes.length > 0 ? gremiaNodes.length : 0])
      .range([
        centerline - (gremiaNodes.length / 2) * globalSettings.largeLineHeight,
        centerline + (gremiaNodes.length / 2) * globalSettings.largeLineHeight,
      ]);

    gremiaNodes = gremiaNodes.map((node, nodeIndex) => {
      return {
        ...node,
        areaType: "ontwikkeltraject",
        position: {
          x: globalSettings.XteamKLL,
          y: gremiaNodesScale(nodeIndex),
        },
      };
    });

    return {
      ...area,
      position: {
        x: areaX,
        y: areaY,
      },
      nodes: summarizeNodes ? nodes : nodes.concat(gremiaNodes),
    };
  });

  return areas;
};
