import PersonCard from "./personCard";
import NonPersonCard from "./nonPersonCard";
import OntwikkeltrajectCard from "./ontwikkeltrajectCard";
import Tag from "./tag";
import { useEffect, useState } from "react";
import { directus_updateNode } from "../../../services/directus/helpers";
import { atom_reloadNodesTrigger } from "../../../recoil/atoms";
import { useSetRecoilState } from "recoil";

import EditBar from "./editBar";
const ModalHeader = ({
  node = null,
  ontwikkeltraject = null,
  updateNode,
  editOnly = false,
  showValidationErrors = false,
  type,
}) => {
  const [editMode, setEditMode] = useState(editOnly);
  const [editedNode, setEditedNode] = useState(node);
  const [userDidEdit, setUserDidEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const setReloadNodesTrigger = useSetRecoilState(atom_reloadNodesTrigger);

  const onChangeFormElement = (e, { name, value, checked, type }) => {
    let nodeAfterChange = {};
    switch (type) {
      case "checkbox":
        nodeAfterChange = { ...editedNode, [name]: checked };

        break;
      default:
        nodeAfterChange = { ...editedNode, [name]: value };
    }

    setEditedNode(nodeAfterChange);
    setUserDidEdit(true);
    if (editOnly) {
      // creation screen
      updateNode(nodeAfterChange);
    }
  };
  const saveChanges = async () => {
    const updateObject = (({
      first_name,
      last_name,
      description,
      role,
      is_contactperson,
    }) => ({
      first_name,
      last_name,
      description,
      role,
      is_contactperson,
    }))(editedNode);

    const updateResponse = await directus_updateNode(node.id, updateObject);
    if (updateResponse) {
      setReloadNodesTrigger(Date.now());
      setEditMode(false);
      updateNode(updateObject);
      setUserDidEdit(false);
    } else {
      setErrorMessage("Er ging iets mis...");
    }
  };

  useEffect(() => {
    setEditedNode(node);
  }, [node]);

  if (type === "ontwikkeltraject") {
    return (
      <section>
        <h6>Ontwikkeltraject</h6>
        <div className="columns nowrap">
          <OntwikkeltrajectCard ontwikkeltraject={ontwikkeltraject} />
        </div>
      </section>
    );
  }

  const typeNames = {
    person: "Contactpersoon",
    experiment: "Experiment",
    gremium: "Groep",
  };

  return (
    <section className={`name ${editMode ? "editing" : null}`}>
      <h6>{typeNames[node.type]}</h6>
      <div className="columns nowrap">
        {node.type === "person" ? (
          <PersonCard
            person={editMode ? editedNode : node}
            editMode={editMode}
            onChangeFormElement={onChangeFormElement}
            showValidationErrors={showValidationErrors}
          />
        ) : (
          <NonPersonCard
            node={editMode ? editedNode : node}
            editMode={editMode}
            onChangeFormElement={onChangeFormElement}
            showValidationErrors={showValidationErrors}
          />
        )}
        <div>
          <Tag area={node.area}>{node.area.title}</Tag>
        </div>
      </div>
      {!editOnly && (
        <EditBar
          editMode={editMode}
          setEditMode={setEditMode}
          saveChanges={saveChanges}
          errorMessage={errorMessage}
          userDidEdit={userDidEdit}
        />
      )}
    </section>
  );
};

export default ModalHeader;
